import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Checkbox,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemButton,
  ListItemIcon,
  Divider,
  Typography,
  Grid,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Add, Remove, Check, Lock } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { apiService } from "../../utils/utils";
import HeaderNaming from "../../components/HeaderNaming/HeaderNaming.jsx";
import { UserProfileContext } from "../../context/UserContext.jsx";

const VendorOptions = () => {
  const navigate = useNavigate();
  const {
    userProfile,
    currentOrganization,
    buyerGuideName,
    scenarioName,
    currentScenarioId,
    currentBuyerGuideId,
    currentPackage,
    currentOrg,
  } = useContext(UserProfileContext);

  const [show, setShow] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [vendorName, setVendorName] = useState("");
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const [checkedOffer, setCheckedOffer] = useState([]);
  const [unlockedVendors, setUnlockedVendors] = useState([]);
  const [vendorsToUnlock, setVendorsToUnlock] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false); // New state for dialog

  const packageLimits = {
    "Solo Starter": 1,
    "Duel Dynamic": 2,
    "Focus Five": 5,
    "All Available": Infinity,
  };

  const unallocatedVendorCount = packageLimits["Solo Starter"];

  const data = {
    "End-user Organisation Name":
      userProfile?.profileType === "endUser" ? userProfile?.companyName : currentOrganization,
    "Buyer Guide Name": buyerGuideName,
    "Scenario Name": scenarioName,
    "Purchased Package": currentPackage?.package_type,
    "Unlocked Vendor count": unlockedVendors.length,
    "Unallocated Vendor count": Math.max(unallocatedVendorCount - unlockedVendors.length, 0),
  };

  const handleToggle = (value, vendor) => () => {
    if (!unlockedVendors.includes(vendor.vendor_name)) {
      // Vendor is not unlocked, do nothing
      return;
    }

    let newChecked;
    let newCheckedOffer = [];

    if (value === "select-all-vendor") {
      const unlockedVendorNames = vendors
        .filter((v) => unlockedVendors.includes(v.vendor_name))
        .map((v) => v.vendor_name);
      if (checked.length === unlockedVendorNames.length) {
        newChecked = [];
        newCheckedOffer = [];
        setCheckedOffer(newCheckedOffer);
      } else {
        newChecked = [...unlockedVendorNames];
        vendors.forEach((item) => {
          if (unlockedVendors.includes(item.vendor_name)) {
            item.offers.forEach((offer) => {
              newCheckedOffer.push(offer.offer_name);
            });
          }
        });
        setCheckedOffer((prev) => {
          const uniqueArray = [...new Set([...prev, ...newCheckedOffer])];
          return uniqueArray;
        });
      }
    } else {
      setVendorName(value);
      const currentIndex = checked.indexOf(value);
      newChecked = [...checked];

      if (currentIndex === -1) {
        setShow(true);
        setSelectedVendor((prev) => [...prev, vendor]);
        newChecked.push(value);
        if (vendor?.offers.some((item) => !checkedOffer.includes(item.offer_name))) {
          const newCheckedOffer = [...vendor.offers.map((item) => item.offer_name)];
          setCheckedOffer((prev) => [...prev, ...newCheckedOffer]);
        }
      } else {
        newChecked.splice(currentIndex, 1);
        const vendorOffers = vendor?.offers.map((item) => item.offer_name);
        const newOfferArray = checkedOffer.filter((item) => !vendorOffers?.includes(item));
        setCheckedOffer(newOfferArray);
      }
    }

    setChecked(newChecked);
  };

  const handleToggleOffer = (value) => () => {
    if (!unlockedVendors.includes(vendorName)) {
      // Vendor is not unlocked, do nothing
      return;
    }

    let newChecked;

    if (value === "select-all-offer") {
      if (
        selectedVendor
          .find((item) => item.vendor_name === vendorName)
          .offers.every((item) => checkedOffer.includes(item.offer_name))
      ) {
        const OfferArray = [...checkedOffer];
        newChecked = OfferArray.filter(
          (item) =>
            !selectedVendor
              .find((vendor) => vendor.vendor_name === vendorName)
              .offers.some((offer) => offer.offer_name === item)
        );
        const newCheckedVendor = checked.filter((item) => item !== vendorName);
        setChecked(newCheckedVendor);
      } else {
        setChecked((prev) => [...prev, vendorName]);
        newChecked = [
          ...checkedOffer,
          ...selectedVendor
            .find((item) => item.vendor_name === vendorName)
            .offers.map((item) => item.offer_name),
        ];
      }
    } else {
      const currentIndex = checkedOffer.indexOf(value);
      newChecked = [...checkedOffer];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
    }

    setCheckedOffer(newChecked);
  };

  const handleVendorClick = (vendor) => {
    if (vendorName !== vendor?.vendor_name || (vendorName === vendor?.vendor_name && !show)) {
      setShow(true);
      setSelectedVendor((prev) => [...prev, vendor]);
      setVendorName(vendor?.vendor_name);
    } else {
      setShow(false);
    }
  };

  const handlePlusClick = (vendor) => {
    setShow(true);
    setSelectedVendor((prev) => [...prev, vendor]);
    setVendorName(vendor?.vendor_name);
  };

  const handleMinusClick = (vendor) => {
    setShow(false);
  };

  const handleUnlockVendor = () => {
    const remainingVendorCount = unallocatedVendorCount - unlockedVendors.length;

    if (vendorsToUnlock.length > remainingVendorCount) {
      setDialogOpen(true); // Open dialog if limit exceeds
    } else {
      setUnlockedVendors((prev) => [...prev, ...vendorsToUnlock]);
      setVendorsToUnlock([]);
    }
  };

  const handleDialogClose = (confirm) => {
    setDialogOpen(false);
    if (confirm) {
      navigate("/packages");
    }
  };

  const handleLockToggle = (vendorName) => () => {
    setVendorsToUnlock((prev) => {
      if (prev.includes(vendorName)) {
        return prev.filter((vendor) => vendor !== vendorName);
      } else {
        return [...prev, vendorName];
      }
    });
  };

  function arraysHaveCommonItem(array1, array2) {
    if (!array1 || !array2) return;
    const setArray2 = new Set(array2);
    for (const item of array1) {
      if (setArray2.has(item.offer_name)) {
        return true;
      }
    }
    return false;
  }

  function loadVendors() {
    const payload = {
      email: userProfile?.email,
      currentOrganisation: currentOrg,
      currentBuyerGuide: currentBuyerGuideId,
      currentScenario: currentScenarioId,
      currentPackage: currentPackage.id,
    };
    apiService(
      "https://newtestfuncpython.azurewebsites.net/api/listOfVendors?",
      {
        method: "POST",
        data: payload,
      },
      setLoading,
      setVendors,
      (error) => {
        console.error("Failed to load vendors:", error);
      }
    );
  }

  const memoizedLoadVendors = useCallback(loadVendors, [
    userProfile,
    currentOrg,
    currentBuyerGuideId,
    currentPackage.id,
    currentScenarioId,
  ]);

  useEffect(() => {
    memoizedLoadVendors();
  }, [memoizedLoadVendors]);

  useEffect(() => {
    const selectedVendors = [];

    vendors &&
      vendors.length > 0 &&
      vendors.forEach((vendor) => {
        if (vendor.isSelected) {
          selectedVendors.push(vendor.vendor_name);
        }
      });
    setChecked(selectedVendors);
  }, [vendors]);

  useEffect(() => {
    const selectedOffers = [];

    if (vendors?.length > 0) {
      vendors?.forEach((item) => {
        item.offers?.forEach((offer) => {
          if (offer?.isSelected) {
            selectedOffers.push(offer.offer_name);
          }
        });
      });
      setCheckedOffer((prev) => {
        const uniqueArray = [...new Set([...prev, ...selectedOffers])];
        return uniqueArray;
      });
    }
  }, [vendors]);

  useEffect(() => {
    if (vendorName) {
      if (
        !arraysHaveCommonItem(
          vendors?.find((item) => item.vendor_name === vendorName)?.offers,
          checkedOffer
        )
      ) {
        const newChecked = checked.filter((vendor) => vendor !== vendorName);
        setChecked(newChecked);
      } else {
        if (checked.includes(vendorName)) return;
        setChecked((prev) => [...prev, vendorName]);
      }
    }
  }, [checkedOffer, vendorName, vendors, checked]);

  return (
    <>
      <HeaderNaming data={data} />

      {!loading ? (
        vendors && vendors?.length > 0 ? (
          <Box sx={{ width: "100%", padding: "20px" }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} md={6}>
                <List sx={{ width: "100%", maxWidth: 500, bgcolor: "" }}>
                  <Typography variant="h3" textAlign="center">
                    Vendor List
                  </Typography>
                  <ListItem key="select-all">
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle("select-all-vendor")}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          color="secondary"
                          edge="start"
                          checked={
                            vendors.filter((v) => unlockedVendors.includes(v.vendor_name)).length >
                              0 &&
                            checked.length ===
                              vendors.filter((v) => unlockedVendors.includes(v.vendor_name)).length
                          }
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            "aria-labelledby": "checkbox-list-label-select-all",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText id="checkbox-list-label-select-all" primary="Select All" />
                    </ListItemButton>
                  </ListItem>
                  <Divider />

                  {vendors.map((value, index) => {
                    const labelId = `checkbox-list-label-${value.vendor_name}`;

                    return (
                      <React.Fragment key={index}>
                        <ListItem key={index}>
                          <ListItemButton
                            role={undefined}
                            dense
                            selected={value.vendor_name === vendorName}
                            onClick={() => handleVendorClick(value)}
                          >
                            <ListItemIcon>
                              {unlockedVendors.includes(value.vendor_name) ? (
                                <Checkbox
                                  color={
                                    vendors
                                      ?.find((item) => item.vendor_name === value.vendor_name)
                                      ?.offers?.every((item) =>
                                        checkedOffer.includes(item.offer_name)
                                      )
                                      ? "secondary"
                                      : "info"
                                  }
                                  edge="start"
                                  checked={checked.indexOf(value.vendor_name) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ "aria-labelledby": labelId }}
                                  onClick={handleToggle(value.vendor_name, value)}
                                />
                              ) : (
                                <IconButton onClick={handleLockToggle(value.vendor_name)}>
                                  {vendorsToUnlock.includes(value.vendor_name) ? (
                                    <Check />
                                  ) : (
                                    <Lock />
                                  )}
                                </IconButton>
                              )}
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.vendor_name}`} />
                          </ListItemButton>
                          {show &&
                          selectedVendor?.find((item) => item.vendor_name === vendorName)
                            ?.vendor_name === value.vendor_name ? (
                            <IconButton
                              edge="end"
                              aria-label="more"
                              onClick={() => handleMinusClick(value)}
                            >
                              <Remove />
                            </IconButton>
                          ) : (
                            <IconButton
                              edge="end"
                              aria-label="more"
                              onClick={() => handlePlusClick(value)}
                            >
                              <Add color="secondary" />
                            </IconButton>
                          )}
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    );
                  })}
                </List>
              </Grid>
              {show && (
                <Grid item xs={12} md={6}>
                  <List sx={{ width: "100%", maxWidth: 500, bgcolor: "" }}>
                    <Typography variant="h3" textAlign="center">
                      {vendorName} - Offer List
                    </Typography>
                    <ListItem key="select-all">
                      <ListItemButton
                        role={undefined}
                        onClick={handleToggleOffer("select-all-offer")}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            color="secondary"
                            edge="start"
                            checked={selectedVendor
                              ?.find((item) => item.vendor_name === vendorName)
                              ?.offers?.every((item) => checkedOffer.includes(item.offer_name))}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              "aria-labelledby": "checkbox-list-label-select-all",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText id="checkbox-list-label-select-all" primary="Select All" />
                      </ListItemButton>
                    </ListItem>
                    <Divider />

                    {selectedVendor
                      ?.find((item) => item.vendor_name === vendorName)
                      ?.offers.map((value, index) => {
                        const labelId = `checkbox-list-label-${value.offer_name}`;

                        return (
                          <React.Fragment key={index}>
                            <ListItem key={index}>
                              <ListItemButton role={undefined} dense>
                                <ListItemIcon>
                                  {unlockedVendors.includes(vendorName) ? (
                                    <Checkbox
                                      color="secondary"
                                      edge="start"
                                      checked={checkedOffer.indexOf(value.offer_name) !== -1}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                      onClick={handleToggleOffer(value.offer_name)}
                                    />
                                  ) : null}
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${value.offer_name}`} />
                              </ListItemButton>
                            </ListItem>
                            <Divider />
                          </React.Fragment>
                        );
                      })}
                  </List>
                </Grid>
              )}
            </Grid>
            <Box
              marginTop={5}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap={2}
            >
              <Button
                sx={{ width: "250px" }}
                color="primary"
                variant="contained"
                disableRipple
                onClick={() => navigate("/edit-scenario/update-use-cases")}
              >
                <Typography color="white"> Save & Continue </Typography>
              </Button>
              <Button
                sx={{ width: "250px" }}
                color="primary"
                variant="contained"
                disableRipple
                onClick={handleUnlockVendor}
              >
                Unlock Vendors
              </Button>
              <Button
                sx={{ width: "250px" }}
                color="secondary"
                variant="contained"
                disableRipple
                onClick={() =>
                  navigate("/results/comparative-analysis", {
                    state: {
                      vendors,
                      checked,
                      checkedOffer,
                      vendorName,
                    },
                  })
                }
              >
                <Typography color="white">Save & Exit to Main Dashboard</Typography>
              </Button>
            </Box>
          </Box>
        ) : (
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Typography variant="body1">No data available</Typography>
          </Box>
        )
      ) : (
        <Box justifyContent="center" alignItems="center" display="flex">
          <Loader />
        </Box>
      )}

      {/* Confirmation Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={() => handleDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Limit Exceeding"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are trying to unlock more vendors than your current package allows. Would you like
            to upgrade your package?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
            Upgrade Package
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VendorOptions;
