import React from 'react';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-xcode";
import './JsonInput.css';
import { useLocation,useNavigate } from 'react-router-dom';
import { TextField, Button } from '@mui/material';  
 
const JsonInput = ({
  inputData,
  onPasteData,
  handleRun,
  handleGenerate,
  handleReview,
  handlePublish,
  description,
  onDescriptionChange,
  handleGenerateFeatures,
  handleReviewFeatures,
  handleMapFeatures,  // New handler for mapping features
  handleMapUseCases,  // New handler for mapping use cases
  setFeatureCount,  // Added prop to set feature count
  setUseCaseCount   // Added prop to set use case count
}) => {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize navigate
 
  // const [featureCount, setFeatureCount] = useState("");  // State to store feature count
  // const [useCaseCount, setUseCaseCount] = useState("");  // State to store use case count
 
  // Identify the flags from the location state
  const isFromVendorOfferMenu = location.state?.fromVendorOfferMenu || false;
  const isFromAddNewOffer = location.state?.fromAddNewOffer || false;
  const isFromGeneratedFeatures = location.state?.fromGeneratedFeatures || false;
  const flag1 = location.state?.flag1 || false;  // Check if user came with flag1
  const flag2 = location.state?.flag2 || false;  // Check if user came with flag2
 
  // Determine if the specific button set should be shown
  const showFeatureButtons = isFromVendorOfferMenu || isFromAddNewOffer || isFromGeneratedFeatures;
 
  const handleJsonChange = (newValue) => {
    onPasteData({ target: { value: newValue } });
  };
 
  return (
    <div className="json-input-container">
      <AceEditor
        mode="json"
        theme="xcode"
        name="input-json"
        onChange={handleJsonChange}
        fontSize={16}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        value={inputData}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
        style={{ height: '70vh', width: '100%' }}
      />
 
      {/* Conditionally render the description box only if not from flag1 or flag2 */}
      {!flag1 && !flag2 && (
        <TextField
          multiline
          rows={4}
          value={description}
          onChange={onDescriptionChange}
          label="Enter description for use case generation"
          variant="outlined"
          fullWidth
          margin="normal"
        />
      )}
 
      {/* Conditionally render buttons based on the source page */}
      {flag1 ? (
        <div className="flag-input-container">
          <TextField
            type="number"
            onChange={(e) => setFeatureCount(Number(e.target.value ))}
            // value={featureCount}
            label="How many Features do you want to map per Use Case?"
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleMapFeatures}>
            Map Features
          </Button>
          <Button
          className="review-button"
            variant="contained"
            color="secondary"
            onClick={() => navigate('/usecase-to-feature')}
            style={{ marginTop: '10px' }}
          >
            Exit to Main Mapping Page
          </Button>
        </div>
      ) : flag2 ? (
        <div className="flag-input-container">
          <TextField
            type="number"
            // value={useCaseCount}
            onChange={(e) => setUseCaseCount(Number(e.target.value))}
            label="How many Use Cases do you want to map per Feature?"
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleMapUseCases}>
            Map Use Cases
          </Button>
          <Button
          className="review-button"
            variant="contained"
            color="secondary"
            onClick={() => navigate('/usecase-to-feature')}
            style={{ marginTop: '10px' }}
          >
            Exit to Main Mapping Page
          </Button>
        </div>
      ) : (
        <>
          {/* Default set of buttons for other paths */}
          {showFeatureButtons ? (
            <>
              <Button variant="contained" color="primary" onClick={handleRun}>
                RUN
              </Button>
              <Button variant="contained" color="primary" onClick={handleGenerateFeatures} style={{ marginTop: '10px' }}>
                Generate Features
              </Button>
              <Button variant="contained" className="review-button" onClick={handleReviewFeatures} style={{ marginTop: '10px' }}>
                Review Features
              </Button>
              <Button variant="contained" color="primary" onClick={handlePublish} style={{ marginTop: '10px' }}>
                Finalize Features
              </Button>
            </>
          ) : (
            <>
              <Button variant="contained" color="primary" onClick={handleRun}>
                RUN
              </Button>
              <Button variant="contained" color="primary" onClick={handleGenerate} style={{ marginTop: '10px' }}>
                Generate Use Cases
              </Button>
              <Button variant="contained" className="review-button" onClick={handleReview} style={{ marginTop: '10px' }}>
                Review Use Cases
              </Button>
              <Button variant="contained" color="primary" onClick={handlePublish} style={{ marginTop: '10px' }}>
                Publish Buyer Guide
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );
};
 
export default JsonInput;
