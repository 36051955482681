import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserProfileContext } from "../context/UserContext";
import { Box, CircularProgress } from "@mui/material";

const PrivateRoute = ({ children }) => {
  const { userProfile, isProfileLoading } = useContext(UserProfileContext);
  // Show a loading indicator while the profile is being fetched
  if (isProfileLoading) {
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Check if userProfile is fully loaded and contains required properties
  return userProfile &&
    userProfile?.companyName &&
    userProfile?.jobTitle &&
    userProfile?.profileType ? (
    children
  ) : (
    <Navigate to="/users" />
  );
};

export default PrivateRoute;
