import React, { useEffect, useState, useContext } from "react";

import {
  Box,
  Dialog,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShareIcon from "@mui/icons-material/Share";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useTheme } from "@emotion/react";
import DataGridTable from "../components/DataGridTable";
import { apiService } from "../utils/utils";
import { UserProfileContext } from "../context/UserContext.jsx";
import { useNavigate } from "react-router-dom";

const SavedReports = () => {
  const { userProfile } = useContext(UserProfileContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [row, setRow] = useState([]);
  const [emailInput, setEmailInput] = useState(""); // State for email input
  const [emailList, setEmailList] = useState([]); // State for list of added emails
  const [emailError, setEmailError] = useState("");
  const [shareNext, setShareNext] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [sharingReport, setSharingReport] = useState(false);
  const [currentReportId, setCurrentReport] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/getSavedReports?",
          {
            method: "POST",
            data: {
              email: userProfile?.email,
            },
          }
        );

        setRow(response);
      } catch (error) {
        console.log("error message:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [userProfile]);
  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/; // Basic email validation regex
    return emailPattern.test(email);
  };
  const handleAddEmail = () => {
    if (!validateEmail(emailInput)) {
      setEmailError("Please enter a valid email");
      return;
    }

    if (emailList.includes(emailInput)) {
      setEmailError("Email is already added");
      return;
    }

    setEmailList([...emailList, emailInput]);
    setEmailInput(""); // Clear input after adding
    setEmailError(""); // Clear error
  };
  const handleRemoveEmail = (emailToRemove) => {
    setEmailList(emailList.filter((email) => email !== emailToRemove));
  };
  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const shareData = async () => {
    try {
      const response = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/shareReport?",
        {
          method: "POST",
          data: {
            currentSender: userProfile?.email,
            shareWithNext: shareNext,
            currentReceiver: emailList,
            currentReport: currentReportId,
          },
        }
      );
      if (response) {
        setNotification((prev) => {
          return {
            ...prev,
            message: "Report is shared successfully",
            open: true,
          };
        });
        setEmailList([]);
        setCurrentReport(null);
      }
    } catch (error) {
      console.log("Request Error :", error);
      setNotification((prev) => {
        return {
          ...prev,
          message: "Report sharing Failed",
          open: true,
          severity: "error",
        };
      });
    }
  };
  const handleShare = async () => {
    if (emailList.length <= 0) {
      setNotification((prev) => ({
        ...prev,
        message: "please enter atleast one email",
        open: true,
        severity: "warning",
      }));
      return;
    } else if (!currentReportId) {
      setNotification((prev) => ({
        ...prev,
        message: "current Report is null",
        open: true,
        severity: "warning",
      }));
      return;
    } else {
      setSharingReport(true);
      await shareData();
      setSharingReport(false);
      setOpenDialogBox(false);
    }
  };
  const columns = [
    {
      field: "reportName",
      headerName: "Report Name",
      editable: true,
      flex: 1,
      headerAlign: "center", // Center header text
      align: "center", // Center cell content
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      renderHeader: () => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          Report Name
        </div>
      ),
    },
    {
      field: "CurrentScenarioName",
      headerName: "Scenario Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      renderHeader: () => (
        <Box style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          Scenario Name
        </Box>
      ),
      renderCell: (params) => <Box>{params.value}</Box>,
    },
    {
      field: "currentBuyerGuideName",
      headerName: "Buyer Guide Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      renderHeader: () => (
        <Box style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          Buyer Guide Name
        </Box>
      ),
      renderCell: (params) => <Box>{params.value}</Box>,
    },
    {
      field: "currentOrganisationName",
      headerName: "End-user Organisation",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      renderHeader: () => (
        <Box style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          End-user Organisation
        </Box>
      ),
      renderCell: (params) => <Box>{params.value}</Box>,
    },
    {
      field: "createdOn",
      headerName: "Created on",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      renderHeader: () => (
        <Box style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          Created on
        </Box>
      ),
      renderCell: (params) => <Box>{params.value}</Box>,
    },
    {
      field: "sharedByName",
      headerName: "Prepared by",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      renderHeader: () => (
        <Box style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          Prepared by
        </Box>
      ),
      renderCell: (params) => <Box>{params.value}</Box>,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      renderHeader: () => (
        <Box style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          Action
        </Box>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // Align icons horizontally
            alignItems: "center", // Align icons vertically
            height: "100%",
          }}
        >
          <IconButton
            onClick={() => {
              navigate("/show-static-report", {
                state: { urlPreview: params?.row?.urlPreview },
              });
            }}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            disabled={!params?.row?.shareWith}
            onClick={() => {
              setOpenDialogBox(true);
              setCurrentReport(params?.row?.id);
            }}
          >
            <ShareIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  return (
    <Box>
      {isLoading ? (
        <Box mt={4} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box
          flexGrow={1}
          sx={{
            "& .super-app-theme--header": {
              backgroundColor: theme.palette.primary.main,
              border: "0.5px solid grey",
            },
          }}
        >
          {" "}
          <Dialog
            open={openDialogBox}
            onClose={() => {
              setCurrentReport(null);
              setEmailList([]);
              setOpenDialogBox(false);
            }}
          >
            <DialogTitle sx={{ backgroundColor: "white" }}>
              Share Report
            </DialogTitle>
            <DialogContent sx={{ color: "black", backgroundColor: "white" }}>
              <List>
                {emailList.map((email) => (
                  <ListItem key={email}>
                    <ListItemText primary={email} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="remove"
                        onClick={() => handleRemoveEmail(email)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  marginTop: "1em",
                }}
              >
                <TextField
                  value={emailInput}
                  onChange={(e) => setEmailInput(e.target.value)}
                  label="Enter email"
                  fullWidth
                  error={!!emailError}
                  helperText={emailError}
                />
                <IconButton onClick={handleAddEmail} color="primary">
                  <CheckIcon />
                </IconButton>
              </Box>
            </DialogContent>
            <DialogActions sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Checkbox
                  checked={shareNext}
                  onClick={() => {
                    setShareNext((prev) => {
                      return !prev;
                    });
                  }}
                />
                <Typography>Share With Next</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  alignContent: "space-around",
                }}
              >
                <Button
                  onClick={() => {
                    setCurrentReport(null);
                    setEmailList([]);
                    setOpenDialogBox(false);
                  }}
                  color="primary"
                >
                  Close
                </Button>
                <Button
                  onClick={
                    sharingReport
                      ? null
                      : () => {
                          handleShare();
                        }
                  }
                  color="primary"
                >
                  {sharingReport ? (
                    <Box>
                      <CircularProgress />
                    </Box>
                  ) : (
                    "Share"
                  )}
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
          <DataGridTable
            rows={row}
            columns={columns}
           
           
          />
        </Box>
      )}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SavedReports;
