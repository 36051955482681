import React, { useContext, useRef, useState } from "react";
import { Box, Typography, Grid, Divider, Button, CircularProgress } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { apiService } from "../../utils/utils";
import CustomizedMenus from "./CustomizedMenus";
import { UserProfileContext } from "../../context/UserContext";
const HeaderNaming = ({ data }) => {
  const {
    userProfile,
    currentOrganizationId,
    currentBuyerGuideId,
    currentPackage,
  } = useContext(UserProfileContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const finalizeButtonStatus = useRef(
    JSON.parse(localStorage.getItem("currentScenario"))?.status
  );
  //const finalizeButtonStatus = useRef("Completed");
  const currentScenarioId = useRef(
    JSON.parse(localStorage.getItem("currentScenario"))?.id
  );

  const isMatchingPath = [
    "/results/comparative-analysis",
    "/results/vendor-details",
    "/results/offer-details",
  ].includes(location.pathname);
  const finalizeScenario = async () => {
    try {
      await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/finalizeScenario?",
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            currentOrganisation: currentOrganizationId,
            currentBuyerGuide: currentBuyerGuideId,
            currentPackage: currentPackage?.id,
            currentScenario: currentScenarioId?.current,
          },
        },
        setLoading,
        (reponse)=>{
            if(reponse.status==="Completed")
            {
                const currentScenario = localStorage.getItem("currentScenario");
                localStorage.setItem("currentScenario",JSON.stringify({...currentScenario,status:"Completed"}))
            }
        },
        (error) => {
          console.error("Failed to create/update users:", error);
        }
      );
    } catch (error) {
      console.log("reponse error ", error);
    }finally{
        setLoading(false)
    }
  };
  return (
    <>
      <Box sx={{ width: "100%", marginBottom: "30px" }}>
        <Grid container spacing={2} alignItems="center">
          {Object.entries(data).map(([label, value]) => (
            <React.Fragment key={label}>
              <Grid item xs={12} sm={3}>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", textAlign: "left" }}
                  data-test-id={`label-${label}`}
                >
                  {isMobile ? label + " : " : label}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Typography
                  data-test-id={`label-${value}`}
                  variant="body1"
                  sx={{ textAlign: "left" }}
                >
                  {isMobile ? value : " : " + value}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        {isMatchingPath && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 3,
            }}
          >
            <Box
              sx={{
                width: "250px",
                display: "flex",
                boxShadow: "0 4px 2px -2px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Button
                sx={{
                  borderRadius: "6px 0px 0px 6px",
                  boxShadow: "none",
                  flex: "2 2 85%",
                }}
                color="primary"
                variant="contained"
                disableRipple
                onClick={loading?null: async () => {

                  if(finalizeButtonStatus.current!=="Completed"){
                  await finalizeScenario();
                  }
                  navigate("/report-settings");
                }}
              >
                {loading?<CircularProgress/>:<Typography color="white">{
                  finalizeButtonStatus.current === "Completed" ?"Report Setting":"Finalise Scenario"
                }</Typography>}
              </Button>
              <CustomizedMenus />
            </Box>
          </Box>
        )}
      </Box>
      <Divider sx={{ marginBottom: 3 }} />
    </>
  );
};

export default HeaderNaming;
