import React, { useState, useEffect, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import JsonInput from "./JsonInput";
import TreeComponent from "./TreeComponent";
import "./MainComponent.css";
import { UserProfileContext } from "../context/UserContext";

function MainComponent() {
  const [jsonInput, setJsonInput] = useState("");
  const [parsedData, setParsedData] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { userProfile } = useContext(UserProfileContext);
  const navigate = useNavigate();
  const [elementId, setElementId] = useState(null);
  const [description, setDescription] = useState(""); // New state for description
  const [featureCount, setFeatureCount] = useState(""); 
  const [useCaseCount, setUseCaseCount] = useState("");

  useEffect(() => {
    const initialData = location.state?.buyerGuideData || location.state?.analysisData || location.state?.data||null;
    if (initialData) {
      const json = JSON.stringify(initialData, null, 2);
      setJsonInput(json);
      setParsedData(initialData);
    }
  }, [location.state]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setJsonInput(e.target.result);
    };
    reader.readAsText(file);
  };

  const handlePasteData = (event) => {
    setJsonInput(event.target.value);
  };

  const handleRun = () => {
    try {
      setLoading(true);
      const parsed = JSON.parse(jsonInput);
      setTimeout(() => {
        setParsedData(parsed);
        setLoading(false);
      }, 1000);
    } catch (e) {
      alert("Invalid JSON data");
      setLoading(false);
    }
  };

    const handleGenerateUseCases = async () => {
    if (!elementId) {
      alert("Please select a rectangle before generating use cases.");
      return;
    }

    const isMatching = elementId === parsedData?.[0]?.buyerGuideId;
    const currentBuyerGuideId = isMatching ? elementId : parsedData?.[0]?.buyerGuideId;
    const reqBody = {
      email: userProfile.email,
      currentBuyerGuideId: currentBuyerGuideId,
      selectedUseCaseId: elementId,
    };

    if (description.trim()) {
      reqBody.userPrompt = description;
    } else {
      reqBody.userPrompt = "";
    }

    try {
      setLoading(true);
      const response = await fetch('https://newtestfuncpython.azurewebsites.net/api/generateUseCasesWithAI', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody),
      });
      if (!response.ok) throw new Error('Network response was not ok.');
      const data = await response.json();
      navigate('/sub-use-cases', { state: { data, currentBuyerGuideId } });
    } catch (error) {
      console.error('Error generating use cases:', error);
      alert('Failed to generate use cases: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleReview = async () => {
    if (!elementId) {
      alert("Please select a rectangle before reviewing use cases.");
      return;
    }

    const isMatching = elementId === parsedData?.[0]?.buyerGuideId;
    const currentBuyerGuideId = isMatching ? elementId : parsedData?.[0]?.buyerGuideId;
    const reviewPayload = {
      email: userProfile.email,
      currentBuyerGuideId: currentBuyerGuideId,
      selectedUseCaseId: elementId
    };

    try {
      setLoading(true);
      const response = await fetch('https://newtestfuncpython.azurewebsites.net/api/reviewSubUseCasesForAnalyst', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reviewPayload),
      });
      const data = await response.json();
      navigate('/sub-use-cases', { state: { data, currentBuyerGuideId } });
    } catch (error) {
      console.error('Error reviewing use cases:', error);
      alert('Failed to review use cases: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateFeatures = async () => {
    if (!elementId) {
      alert("Please select a rectangle before generating features.");
      return;
    }

    console.log("Parsed Data:", parsedData);
    const offerId = parsedData?.[0]?.offerId;
    const isMatching = elementId === offerId;
    const currentOfferId = isMatching ? elementId : parsedData?.[0]?.offerId;
    // const featureId = parsedData?.[0]?.offerId;
    // const isMatching = elementId === featureId;
    // const currentOfferId = isMatching ? elementId : parsedData?.[0]?.offerId;

    const storedVendorId = localStorage.getItem('currentVendorId');
    console.log(storedVendorId);

    const reqBody = {
      email: userProfile.email,
      currentFeatureId: elementId,
      currentOfferId: currentOfferId,
      userPrompt: description,
      currentVendorId: storedVendorId
    };

    // console.log("Payload for generate features-->",reqBody);
    

    try {
      setLoading(true);
      const response = await fetch('https://newtestfuncpython.azurewebsites.net/api/generateFeaturesWithAi', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody),
      });
      if (!response.ok) throw new Error('Network response was not ok.');
      const data = await response.json();
      navigate('/generated-features', { state: { data, currentOfferId } });
    } catch (error) {
      console.error('Error generating features:', error);
      alert('Failed to generate features: ' + error.message);
    } finally {
      setLoading(false);
    }
  };




  const handleReviewFeatures = async () => {
    if (!elementId) {
      alert("Please select a rectangle before reviewing features.");
      return;
    }

    // console.log("Parsed Data:", parsedData);
    const offerId = parsedData?.[0]?.offerId;
    const isMatching = elementId === offerId;
    const currentOfferId = isMatching ? elementId : parsedData?.[0]?.offerId;

    const reviewPayload = {
      email: userProfile.email,
      currentOfferId: currentOfferId,
      currentFeatureId: elementId,
    };

    // console.log("Review Payload:", reviewPayload);
    try {
      setLoading(true);
      const response = await fetch('https://newtestfuncpython.azurewebsites.net/api/reviewOfferFeatures', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reviewPayload),
      });

      if (!response.ok) throw new Error('Network response was not ok.');
      const data = await response.json();
      navigate('/review-vendor-offer', { state: { data, currentOfferId } });
    } catch (error) {
      console.error('Error reviewing features:', error);
      alert('Failed to review features: ' + error.message);
    } finally {
      setLoading(false);
    }
  };



  const handleMapFeatures = async () => {
    if (!elementId) {
      alert("Please select a rectangle before mapping features.");
      return;
    }
    try {
      setLoading(true);
      const storedVendorId = localStorage.getItem('currentVendorId');
      const currentbuyerGuideData = JSON.parse(localStorage.getItem('currentbuyerGuideData'));
      const offerId = parsedData?.[0]?.offerId;
      const currentOfferId = elementId === offerId ? elementId : offerId;

      const storedMappingModelData = JSON.parse(localStorage.getItem("mappingModelData"));
      const filterData = JSON.parse(localStorage.getItem("filterData"));

      const payload = {
        email: userProfile.email,
        currentBuyerGuideId: currentbuyerGuideData?.buyerGuideId,
        currentVendorId: storedVendorId,
        currentOfferId: currentOfferId,
        selectedFeatureId: elementId,
        currentPageNumber: 1,
        highestMatches: Number(featureCount),  // Set featureCount as highestMatches
        recommendationsPerPage: 10,
        modelAndParameterSelection: storedMappingModelData?.mappingModelData,
        filtersOnRecommendations: filterData?.filtersOnRecommendations
      };

      const response = await fetch('https://newtestfuncpython.azurewebsites.net/api/mappingRecommendationForSepecificFeature', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      
      if (!response.ok) throw new Error('Network response was not ok.');
      
      const data = await response.json();
      navigate("/feature-recommendation", {
        state: { pageType: "specificFeature", data: data, payload: payload ,endPoint:"mappingRecommendationForSepecificFeature"},
      });
      
    } catch (error) {
      alert("Failed to map features: " + error.message);
    } finally {
      setLoading(false);
    }
  };





  const handleMapUseCases = async () => {
    if (!elementId) {
      alert("Please select a rectangle before mapping use cases.");
      return;
    }
    try {
      setLoading(true);

      const isMatching = elementId === parsedData?.[0]?.buyerGuideId;
      const currentBuyerGuideId = isMatching ? elementId : parsedData?.[0]?.buyerGuideId;
      const currentVendorData  = JSON.parse(localStorage.getItem('currentVendorData'));
      const currentOfferData = JSON.parse(localStorage.getItem("currentOfferData"))
      const storedMappingModelData = JSON.parse(localStorage.getItem("mappingModelData"));
      const filterData = JSON.parse(localStorage.getItem("filterData"));

      const payload = {
        email: userProfile.email,
        currentBuyerGuideId : currentBuyerGuideId,
        currentVendorId: currentVendorData?.vendorId,
        currentOfferId: currentOfferData?.offerId,
        selectedUseCaseId: elementId,
        currentPageNumber: 1,
        highestMatches: Number(useCaseCount),  // Set useCaseCount as highestMatches
        recommendationsPerPage: 10,
        modelAndParameterSelection: storedMappingModelData?.mappingModelData,
        filtersOnRecommendations: filterData?.filtersOnRecommendations
      };

      const response = await fetch('https://newtestfuncpython.azurewebsites.net/api/mappingRecommendationForSpecificUseCase', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!response.ok) throw new Error('Network response was not ok.');

      const data = await response.json();

      navigate("/usecase-recommendations", {
        state: { pageType: "specificUseCase", data: data, payload: payload, endPoint:"mappingRecommendationForSpecificUseCase",highestMatches:Number(useCaseCount) },
      });

    } catch (error) {
      alert("Failed to map use cases: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const onNodeClick = useCallback((elementId) => {
    setElementId(elementId);
  }, []);

  return (
    <div className="MainComponent">
      <JsonInput
        onFileUpload={handleFileUpload}
        onPasteData={handlePasteData}
        inputData={jsonInput}
        handleRun={handleRun}
        handleGenerate={handleGenerateUseCases}
        handleReview={handleReview}
        description={description}
        onDescriptionChange={(e) => setDescription(e.target.value)}
        handleGenerateFeatures={handleGenerateFeatures}
        handleReviewFeatures={handleReviewFeatures}
        handleMapFeatures={handleMapFeatures}  // New handler
        handleMapUseCases={handleMapUseCases}  // New handler
        setFeatureCount={setFeatureCount}  // Pass setFeatureCount to JsonInput
        setUseCaseCount={setUseCaseCount}  // Pass setUseCaseCount to JsonInput
      />
      {loading && <div className="loading-spinner"></div>}
      {!loading && parsedData && (
        <TreeComponent
          data={parsedData}
          onUpdateJson={setParsedData}
          onNodeClick={onNodeClick}
        />
      )}
    </div>
  );
}

export default MainComponent;
