import React, { createContext, useCallback, useEffect, useState } from "react";
import { apiService } from "../utils/utils";
import { useMsal } from "@azure/msal-react";

// Create the context
const UserProfileContext = createContext();

// Create a provider component
const UserProfileProvider = ({ children }) => {
  const [updateUI, setUpdateUI] = useState(false);
  const { instance, accounts, inProgress } = useMsal();

  //profile
  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem("user_data")) || null
  );
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [unlockVendorCount, setUnlockVendorCount] = useState(0);
  const [isConsultant, setIsConsultant] = useState(false); //
  const [azureProfile, setAzureProfile] = useState(null);
  const [isUpdated, setUpdating] = useState(false);
  const [primaryMetric, setPrimaryMetric] = useState("offerScore");
  const [numVendors, setNumVendors] = useState(3);
  const [numOffers, setNumOffers] = useState(3);
  const [offerName, setOfferName] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [offerId, setOfferId] = useState(null);
  const [vendorId, setVendorId] = useState(null);
  const [thresholdType, setThresholdType] = useState("maximumNumberOfVendors");
  const [currentOrganization, setCurrentOrganization] = useState("");
  const [currentOrganizationId, setCurrentOrganizationId] = useState(null);
  const [currentBuyerGuideId, setCurrentBuyerGuideId] = useState(null);
  const [currentScenarioId, setCurrentScenarioId] = useState(null);
  const [currentPackage, setCurrentPackage] = useState(null);
  const [interestedBuyerGuides, setInterestedBuyerGuides] = useState(
    JSON.parse(localStorage.getItem("intrestedbuyer")) || []
  );
  const [interestedBuyerGuidesLoading, setInterestedBuyerGuidesLoading] = useState(false); //
  const [updateInterestedBuyerGuides, setUpdateInterestedBuyerGuides] = useState(true);
  const [loadingBuyerGuide, setIsLoading] = useState(true);
  const [buyerGuideName, setBuyerGuideName] = useState("");
  const [scenarioName, setScenarioName] = useState("");
  const [userUnits, setUserUnits] = useState(1);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [packageCount, setPackageCount] = useState(0);
  const [orgList, setOrgList] = useState([]);
  const [smartData, setSmartData] = useState({
    count: 0,
    items: [],
    otherData: {},
  });
  const [lowData, setLowData] = useState({
    count: 0,
    items: [],
    otherData: {},
  });
  const [budgetData, setBudgetData] = useState({
    count: 0,
    items: [],
    otherData: {},
  });
  const [premiumData, setPremiumData] = useState({
    count: 0,
    items: [],
    otherData: {},
  });
  const [relevantVendorsData, setRelevantVendorsData] = useState({
    count: 0,
    items: [],
    otherData: {},
  });
  const [relevantOffersData, setRelevantOffersData] = useState({
    count: 0,
    items: [],
    otherData: {},
  });
  const [initialData, setInitialData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [budgetLoading, setBudgetLoading] = useState(true);
  const [lowLoading, setLowLoading] = useState(true);
  const [premiumLoading, setPremiumLoading] = useState(true);
  const [valyouMatrixData, setValyouMatrixData] = useState(null);
  const [, setError] = useState(null);

  //Allocation Pages
  const [availableUnitsForAllocation, setAvailableUnitsForAllocation] = useState([]);

  const fetchUserProfile = useCallback(
    async (email) => {
      try {
        setIsProfileLoading(true);
        const res = await apiService(
          `https://newtestfuncpython.azurewebsites.net/api/getUserProfile?`,
          { method: "POST", data: { email: email } },
          null,
          null,
          (error) => {
            console.log("Failed to fetch profile:", error);
          }
        );
        setUserProfile(res);
        localStorage.setItem("user_data", JSON.stringify(res));
      } finally {
        setIsProfileLoading(false);
      }
    },
    [setIsProfileLoading, setUserProfile]
  );

  const updateUserProfile = async (profileData) => {
    setIsProfileLoading(true);
    await apiService(
      "https://newtestfuncpython.azurewebsites.net/api/CreateUpdateProfile?",
      {
        method: "POST",
        data: {
          displayName: profileData?.displayName,
          email: profileData?.email,
          givenName: profileData?.givenName,
          surname: profileData?.surname,
          profileType: profileData?.profileType,
          companyName: profileData?.companyName,
          jobTitle: profileData?.jobTitle,
        },
      },
      setUpdating,
      setUserProfile,
      (error) => {
        // Handle error if needed
        console.error("Failed to create/update users:", error);
      }
    );
    fetchUserProfile(profileData?.email);
    setIsProfileLoading(false);
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const currentOrg =
    userProfile && userProfile.profileType === "endUser"
      ? userProfile.endUser_companyId
      : currentOrganizationId;

  useEffect(() => {
    const fetchData = async () => {
      if (inProgress === "none" && accounts.length > 0) {
        try {
          const accessToken = await instance.acquireTokenSilent({
            scopes: ["https://graph.microsoft.com/User.Read"],
            account: accounts[0],
          });
          const response = await fetch("https://graph.microsoft.com/v1.0/me", {
            headers: {
              Authorization: `Bearer ${accessToken.accessToken}`,
            },
          });
          const data = await response.json();
          if (data && data.mail) {
            setAzureProfile(data);
            fetchUserProfile(data?.mail);
          }
        } catch (error) {
          console.error("Error fetching user profile from Graph API:", error);
        }
      }
    };
    fetchData();
  }, [instance, accounts, inProgress, updateUI, fetchUserProfile]);

  useEffect(() => {
    /*if(currentOrg&&scenarioName)
    {   
        sessionStorage.setItem("reportData",JSON.stringify({[currentOrg]:{[scenarioName]:{}}}));
    }*/
    setCurrentOrganization(localStorage?.getItem("currentOrganization"));
    setBuyerGuideName(localStorage?.getItem("buyerGuideName"));
    setScenarioName(localStorage?.getItem("scenarioName"));
    setOfferName(localStorage?.getItem("currentOffer"));
    setOfferId(localStorage?.getItem("currentOfferId"));
    setVendorId(localStorage.getItem("currentVendorId"));
    setCurrentOrganizationId(localStorage?.getItem("currentOrganizationId"));
    setCurrentPackage(JSON.parse(localStorage?.getItem("currentPackage")));
    setCurrentBuyerGuideId(localStorage?.getItem("currentBuyerGuideId"));
    setCurrentScenarioId(localStorage?.getItem("currentScenarioId"));
  }, [currentOrg, scenarioName]);

  const fetchInterestedBuyerGuides = async () => {
    try {
      setInterestedBuyerGuidesLoading(true);
      const response = await apiService(
        `https://newtestfuncpython.azurewebsites.net/api/getInterestedBuyerGuides?`,
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            currentOrganisation:
              userProfile && userProfile?.profileType === "endUser"
                ? userProfile?.endUser_companyId
                : currentOrganizationId,
          },
        },
        setIsLoading,
        null,
        (err) => {
          console.log(err);
        }
      );
      setInterestedBuyerGuides(response);
      localStorage.setItem("interestedbuyer", JSON.stringify(response));
    } finally {
      setInterestedBuyerGuidesLoading(false);
    }
  };

  useEffect(() => {
    if (!!currentOrganizationId) fetchInterestedBuyerGuides();
    //do not remove the below to avoid warning for not including fetchInterestedBuyerGuides in dependency
    // eslint-disable-next-line
  }, [currentOrganizationId]);

  useEffect(() => {
    if (userProfile) {
      apiService(
        `https://newtestfuncpython.azurewebsites.net/api/getAvailableUnitsForAdminAllocation?`,
        {
          method: "POST",
          data: {
            // email: "babluv613@gmail.com",
            email: userProfile?.email,
          },
        },
        setIsLoading,
        setAvailableUnitsForAllocation,
        (err) => {
          console.log(err);
        }
      );
    }
  }, [userProfile]);

  // Use effect for smart data

  const fetchSmartData = useCallback(async () => {
    if (userProfile && currentOrg && currentBuyerGuideId && currentScenarioId) {
      const payload = {
        email: userProfile?.email,
        currentOrganisation: currentOrg,
        currentBuyerGuide: currentBuyerGuideId,
        currentScenario: currentScenarioId,
        currentPackage: currentPackage?.id,
      };

      try {
        setLoading(true);
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/smartValyouOfferCountAndNames?",
          {
            method: "POST",
            data: payload,
          }
        );

        if (response) {
          setSmartData({
            count: response.SmartOfferCount,
            items: response.SmartOfferDetails.map((offer) => offer.SmartOfferNames),
            otherData: response?.SmartOfferDetails?.reduce((obj, item) => {
              obj[item?.SmartOfferNames] = item;
              return obj;
            }, {}),
          });
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    }
  }, [userProfile, currentOrg, currentBuyerGuideId, currentScenarioId, currentPackage?.id]);

  // Use effect for low data
  const fetchLowData = useCallback(async () => {
    if (userProfile && currentOrg && currentBuyerGuideId && currentScenarioId) {
      const payload = {
        email: userProfile?.email,
        currentOrganisation: currentOrg,
        currentBuyerGuide: currentBuyerGuideId,
        currentScenario: currentScenarioId,
        currentPackage: currentPackage?.id,
      };

      try {
        setLowLoading(true); // Start loading
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/lowValyouOfferCountAndNames?",
          {
            method: "POST",
            data: payload,
          }
        );

        if (response) {
          setLowData({
            count: response.LowOfferCount,
            items: response?.LowOfferDetails?.map((offer) => offer.LowOfferNames),
            otherData: response?.LowOfferDetails?.reduce((obj, item) => {
              obj[item?.LowOfferNames] = item;
              return obj;
            }, {}),
          });
          setLowLoading(false);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err);
      } finally {
        setLowLoading(false); // End loading
      }
    }
  }, [userProfile, currentOrg, currentBuyerGuideId, currentScenarioId, currentPackage?.id]);

  // Use effect for budget data
  const fetchBudgetData = useCallback(async () => {
    if (userProfile && currentOrg && currentBuyerGuideId && currentScenarioId) {
      const payload = {
        email: userProfile?.email,
        currentOrganisation: currentOrg,
        currentBuyerGuide: currentBuyerGuideId,
        currentScenario: currentScenarioId,
        currentPackage: currentPackage?.id,
      };

      try {
        setBudgetLoading(true); // Start loading
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/budgetValyouOfferCountAndNames?",
          {
            method: "POST",
            data: payload,
          }
        );

        if (response) {
          setBudgetData({
            count: response.BudgetOfferCount,
            items: response.BudgetOfferDetails.map((offer) => offer.BudgetOfferNames),
            otherData: response?.BudgetOfferDetails?.reduce((obj, item) => {
              obj[item?.BudgetOfferNames] = item;
              return obj;
            }, {}),
          });
          setBudgetLoading(false);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err);
      } finally {
        setBudgetLoading(false); // End loading
      }
    }
  }, [userProfile, currentOrg, currentBuyerGuideId, currentScenarioId, currentPackage?.id]);

  // Use effect for premium data
  const fetchPremiumData = useCallback(async () => {
    if (userProfile && currentOrg && currentBuyerGuideId && currentScenarioId) {
      const payload = {
        email: userProfile?.email,
        currentOrganisation: currentOrg,
        currentBuyerGuide: currentBuyerGuideId,
        currentScenario: currentScenarioId,
        currentPackage: currentPackage?.id,
      };

      try {
        setPremiumLoading(true); // Start loading
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/premiumValyouOfferCountAndNames?",
          {
            method: "POST",
            data: payload,
          }
        );

        if (response) {
          setPremiumData({
            count: response?.PremiumOfferCount,
            items: response.PremiumOfferDetails?.map((offer) => offer?.PremiumOfferNames),
            otherData: response?.PremiumOfferDetails?.reduce((obj, item) => {
              obj[item?.PremiumOfferNames] = item;
              return obj;
            }, {}),
          });
          setPremiumLoading(false);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err);
      } finally {
        setPremiumLoading(false); // End loading
      }
    }
  }, [userProfile, currentOrg, currentBuyerGuideId, currentScenarioId, currentPackage?.id]);

  // Use effect for relevant vendors data
  const fetchRelevantVendors = useCallback(async () => {
    if (userProfile && currentOrg && currentBuyerGuideId && currentScenarioId) {
      const payload = {
        email: userProfile?.email,
        currentOrganisation: currentOrg,
        currentBuyerGuide: currentBuyerGuideId,
        currentScenario: currentScenarioId,
        currentPackage: currentPackage?.id,
        displayUseCasesOptions: "Both",
      };

      try {
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/relevantVendorsCountAndNames?",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: payload,
          }
        );

        if (response) {
          setRelevantVendorsData({
            count: response?.VendorCount,
            items: response?.VendorDetails?.map((offer) => offer?.VendorName) || [],
          });
        }
      } catch (err) {
        console.error("Failed to fetch vendors:", err);
        setError(err);
      }
    }
  }, [userProfile, currentOrg, currentBuyerGuideId, currentScenarioId, currentPackage?.id]);

  // Use effect for relevant offecrs data
  const fetchRelevantOffers = useCallback(async () => {
    if (userProfile && currentOrg && currentBuyerGuideId && currentScenarioId) {
      const payload = {
        email: userProfile?.email,
        currentOrganisation: currentOrg,
        currentBuyerGuide: currentBuyerGuideId,
        currentScenario: currentScenarioId,
        currentPackage: currentPackage?.id,
        displayUseCasesOptions: "Both",
      };
      try {
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/relevantOfferCountAndNames?",
          {
            method: "POST",

            data: payload,
          }
        );

        if (response) {
          setRelevantOffersData({
            count: response?.OfferCount,
            items: response?.OfferDetails.map((offer) => offer.OfferName) || [],
          });
        }
      } catch (err) {
        console.error("Failed to fetch vendors:", err);
        setError(err);
      }
    }
  }, [userProfile, currentOrg, currentBuyerGuideId, currentScenarioId, currentPackage?.id]);

  return (
    <UserProfileContext.Provider
      value={{
        userProfile,
        updateUserProfile,
        isProfileLoading,
        azureProfile,
        updateUI,
        setUpdateUI,
        primaryMetric,
        setPrimaryMetric,
        setCurrentOrganization,
        currentOrganization,
        // purchasePackage,
        // setPurchasePackage,
        interestedBuyerGuides,
        setUpdateInterestedBuyerGuides,
        updateInterestedBuyerGuides,
        buyerGuideName,
        setBuyerGuideName,
        scenarioName,
        setScenarioName,
        numVendors,
        setNumVendors,
        numOffers,
        setNumOffers,
        thresholdType,
        setThresholdType,

        unlockVendorCount,
        setUnlockVendorCount,

        userUnits,
        setUserUnits,

        packageCount,
        setPackageCount,

        smartData,
        setSmartData,
        fetchSmartData,

        lowData,
        setLowData,
        fetchLowData,

        budgetData,
        setBudgetData,
        fetchBudgetData,

        premiumData,
        setPremiumData,
        fetchPremiumData,

        relevantVendorsData,
        setRelevantVendorsData,
        fetchRelevantVendors,

        relevantOffersData,
        setRelevantOffersData,
        fetchRelevantOffers,

        orgList,
        setOrgList,

        vendorName,
        setVendorName,

        setIsConsultant,
        isConsultant,
        interestedBuyerGuidesLoading,

        currentOrg,

        offerId,
        setOfferId,

        vendorId,
        setVendorId,

        fetchInterestedBuyerGuides,

        initialData,
        setInitialData,
        isLoading,
        setLoading,
        loadingBuyerGuide,
        budgetLoading,
        premiumLoading,
        lowLoading,
        offerName,
        setOfferName,
        setCurrentOrganizationId,
        currentOrganizationId,
        availableUnitsForAllocation,
        isUpdated,
        notification,
        setNotification,
        handleCloseNotification,
        currentPackage,
        setCurrentPackage,
        currentBuyerGuideId,
        setCurrentBuyerGuideId,
        currentScenarioId,
        setCurrentScenarioId,
        valyouMatrixData,
        setValyouMatrixData,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};

export { UserProfileContext, UserProfileProvider };
