import { Box, Typography } from "@mui/material";
import React from "react";

const NoDataAvailable = React.forwardRef(({ message = "No Data Available" }, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: ref ? "70px" : "180px",
      }}
    >
      <Typography
        variant="p"
        sx={{
          textAlign: "center",
          whiteSpace: "nowrap", // Prevent text overflow.
          overflow: "hidden", // Hide text overflow if too long.
          textOverflow: "ellipsis", // Show ellipsis for long text.
        }}
      >
        {message}
      </Typography>
    </Box>
  );
});
export default NoDataAvailable;
