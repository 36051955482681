import React, { useContext, useEffect, useState } from "react";
import DataGridTable from "../../../components/DataGridTable";
import theme from "../../../theme/theme";
import { Box, CircularProgress, Divider, IconButton } from "@mui/material";
import "../../GridLayout/GridComponent.css";

//import { apiService } from "../../../utils/utils";
import { UserProfileContext } from "../../../context/UserContext";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { apiService } from "../../../utils/utils";
import NoDataAvilable from "../../../components/NoDataAvilable";

const api = {
  top_support_mandatory:
    "https://newtestfuncpython.azurewebsites.net/api/top5SupportedUseCasesMandatoryForVendor",
  top_support_overall:
    "https://newtestfuncpython.azurewebsites.net/api/top5SupportedUseCasesOverallForVendor",
  top_unsupported_mandtory:
    "https://newtestfuncpython.azurewebsites.net/api/top5UnsupportedUseCasesMandatoryForVendor",
  top_unsupported_overall:
    "https://newtestfuncpython.azurewebsites.net/api/top5UnsupportedUseCasesOverallForVendor",
  top_unused_generic:
    "https://newtestfuncpython.azurewebsites.net/api/top5UnusedfeaturesNoAssocisationsForVendor",
  top_unused_notRequired:
    "https://newtestfuncpython.azurewebsites.net/api/top5UnusedFeaturesMarkedUnnecessaryForVendor",
};
export default function CommonVendorDetailTable({ menu, widgetKey, widgetLabels, setLayout }) {
  const {
    userProfile,
    currentOrg,
    buyerGuideName,
    scenarioName,
    offerName,
    currentOrganizationId,
    currentScenarioId,
    currentBuyerGuideId,
    currentPackage,
    vendorId,
  } = useContext(UserProfileContext);

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("No Data Available");

  useEffect(() => {
    async function fetchData() {
      try {
        const payload = {
          email: userProfile?.email,
          currentOrganisation: currentOrganizationId,
          currentBuyerGuide: currentBuyerGuideId,
          currentPackage: currentPackage.id,
          currentScenario: currentScenarioId,
          currentVendor: vendorId,
        };

        //API Call
        const response = await apiService(
          api[widgetKey],
          {
            method: "POST",
            data: payload,
          },
          setLoading
        );

        response?.message
          ? setMessage(response?.message)
          : (() => {
              // Get column keys where the key is "relativeImportance"
              const columnKeys = Object.keys(response[0]).filter(
                (key) => key === "relativeImportance"
              );

              // Define extra columns with custom configuration
              const extraColumns = {
                field: "widgetLabel",
                headerName: widgetLabels,
                flex: 1,
                headerClassName: "super-app-theme--header",
                minWidth: 250,
                renderCell: ShowPath,
              };

              // Map through the filtered column keys to create the column definitions
              const columns = columnKeys.map((key) => ({
                field: key,
                headerName: key === "relativeImportance" ? "Relative Importance" : key,
                flex: 1,
                minWidth: 200,
                headerClassName: "super-app-theme--header",
                renderCell: (params) => (
                  <Box
                    sx={{
                      overflowY: "hidden",
                      display: "flex",
                      justifyContent: key === "relativeImportance" ? "center" : "flex-start",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {params.value}
                  </Box>
                ),
              }));

              // Update the columns state with the new columns including extraColumns
              setColumns([extraColumns, ...columns]);

              // Update the rows state by transforming the response data
              setRows(() => transformData(response, widgetLabels));

              // Adjust layout based on the response length
              setLayout((prev) =>
                prev.map((item) =>
                  item.i !== widgetKey
                    ? item
                    : {
                        ...item,
                        h: 1 + response.length,
                      }
                )
              );
            })();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, [
    userProfile,
    currentOrg,
    buyerGuideName,
    scenarioName,
    offerName,
    setLayout,
    widgetKey,
    widgetLabels,
    currentBuyerGuideId,
    currentOrganizationId,
    currentPackage.id,
    currentScenarioId,
    vendorId,
  ]);
  return loading ? (
    // If loading is true, display a loading spinner inside a box.
    <Box sx={{ height: "70px", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <CircularProgress />
    </Box>
  ) : rows.length > 0 ? (
    <Box
      sx={{
        "& .super-app-theme--header": {
          backgroundColor: theme.palette.primary.main,
          color: "white",
        },
      }}
    >
      <Box
        className="widget-header-container"
        sx={{
          width: "100%",
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Divider sx={{ width: "95%", marginY: "10px" }} />
      </Box>
      <Box className="thinner-scrollbar" sx={{ width: "100%", overflow: "auto" }}>
        <DataGridTable
          rows={rows}
          columns={columns}
          menu={menu}
          sx={{
            "& .MuiDataGrid-cell": {
              border: "0.5px solid grey",
              wordWrap: "break-word",
              whiteSpace: "normal",
            },
          }}
        />
      </Box>
    </Box>
  ) : (
    // If no data (rows.length === 0), display a message.
    <NoDataAvilable widgetLabels={widgetLabels} widgetKey={widgetKey} message={message} />
  );
}

const ShowPath = (params) => {
  const [showCell, setShowCell] = useState(false);
  const { value } = params;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        width: "100%",
        overflowY: "hidden",
        gap: "0px 10px",
      }}
    >
      <Box>
        <IconButton
          onClick={() => {
            console.log(showCell);
            console.log(params);
            setShowCell(!showCell);
          }}
        >
          {showCell ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
        </IconButton>
      </Box>
      <Box>
        {showCell
          ? value.map((item, index) => (
              <span key={index}>
                {item}
                {index < value.length - 1 && (
                  <span
                    style={{
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                      fontSize: "large",
                    }}
                  >
                    {" "}
                    &gt;{" "}
                  </span>
                )}
              </span>
            ))
          : value[value.length - 1]}
      </Box>
    </Box>
  );
};

const transformData = (data) => {
  return data.map((item, index) => {
    return {
      id: index + 1,
      widgetLabel: item.path,
      relativeImportance: item.relativeImportance,
    };
  });
};
