import {
  Box,
  useTheme,
  ThemeProvider,
  Checkbox,
  Pagination,
  CircularProgress,
  Button,
  Typography,
  IconButton
} from "@mui/material";
import React, { useEffect, useState, useContext, useRef } from "react";
import HeaderNaming from "../components/HeaderNaming/HeaderNaming";
import { useLocation } from "react-router-dom";
import { UserProfileContext } from "../context/UserContext";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { apiService } from "../utils/utils";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
function MappingRecommendation() {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [pageData, setPageData] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const totalPages = useRef(0);
  const pageType = location.state?.pageType;
  const data = location.state?.data;
  const endPoint = location.state?.endPoint;
  const payload = location.state?.payload;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const { userProfile } = useContext(UserProfileContext);
  const [selectAcceptAll, setSelectAll] = useState(false);
  const [selectRejectAll, setRejectAll] = useState(false);
  const currentbuyerGuideData=JSON.parse(localStorage.getItem("currentbuyerGuideData"));
  const currentVendorData =  JSON.parse(localStorage.getItem("currentVendorData"));
  const currentOfferData = JSON.parse(localStorage.getItem("currentOfferData"));

  const HeaderData = {
    "Buyer Guide": currentbuyerGuideData?.buyerGuideName||"",
    "Vendor Name": currentVendorData?.vendorName,
    "Offer Name": currentOfferData?.offerName
  }
  useEffect(() => {
    totalPages.current = data?.totalPages;
    setCurrentPageNumber(data?.currentPageNumber);
    setPageData(() => {
      return data?.recommendations.map((item, index) => {
        return { ...item, id: index };
      });
    });
    setIsLoading(false);
  }, [totalPages, data]);
  const fetchPagedata = async (pageNumber) => {
    try {
      const reponse = await apiService(
        `https://newtestfuncpython.azurewebsites.net/api/${endPoint}`,
        {
          method: "POST",
          data: {
            ...payload,
            currentPageNumber: pageNumber,
          },
        }
      );
      setPageData(() => {
        return reponse?.recommendations.map((item, index) => {
          return { ...item, id: index };
        });
      });
    } catch (error) {
      console.log("request Error :", error);
    }
  };
  const saveData = async () => {
    try {
      const reponse = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/saveMappingToDB",
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            recommendations: {
              totalPages: totalPages.current,
              currentPageNumber: currentPageNumber,
              recommendations: pageData,
            },
          },
        }
      );
      return reponse;
    } catch (error) {
      console.log("request Error:", error);
    }
  };

  const handlePageChange = async (event, pageNumber) => {
    let CheckFlag = false;
    for (let i = 0; i < pageData?.length; i++) {
      if (
        !(
          pageData[i]?.acceptedBy.includes(userProfile?.email) ||
          pageData[i]?.rejectedBy.includes(userProfile?.email)
        )
      ) {
        CheckFlag = true;
        break;
      }
    }
    if (CheckFlag) {
      alert("please Either Accept or Reject all the UseCase");
      return;
    } else {
      const reponse = await saveData();
      if (reponse) {
        await fetchPagedata(pageNumber);
        setCurrentPageNumber(pageNumber);
      }
    }
  };
  const acceptAll = () => {
    console.log("selectAcceptAll",selectAcceptAll);
    setPageData((prev) => {
      return prev.map((item) => {
        if (!item.acceptedBy.includes(userProfile?.email)&&!selectAcceptAll) {
          return {
            ...item,
            rejectedBy: item?.rejectedBy?.filter((item) => {
              return item !== userProfile?.email;
            }),
            acceptedBy: [...item?.acceptedBy, userProfile?.email],
          };
        } else if (item.acceptedBy.includes(userProfile?.email)&&selectAcceptAll) {
          return {
            ...item,
            rejectedBy: item?.rejectedBy?.filter((item) => {
              return item !== userProfile?.email;
            }),
            acceptedBy: item?.acceptedBy?.filter((item) => {
              return item !== userProfile?.email;
            }),
          };
        }else{
         return item
         }
      });
    });
  };
  const rejectAll = () => {
    setPageData((prev) => {
      return prev.map((item) => {
        if (!item.rejectedBy.includes(userProfile?.email)&&!selectRejectAll) {
          return {
            ...item,
            rejectedBy: [...item?.rejectedBy, userProfile?.email],
            acceptedBy: item?.acceptedBy?.filter((item) => {
              return item !== userProfile?.email;
            }),
          };
        } else if (item.rejectedBy.includes(userProfile?.email)&&selectRejectAll){
          return {
            ...item,
            rejectedBy: item?.rejectedBy?.filter((item) => {
              return item !== userProfile?.email;
            }),
            acceptedBy: item?.acceptedBy?.filter((item) => {
              return item !== userProfile?.email;
            }),
          };
        }else{
         return item
         }

      });
    });
  };
  const columnDataOverAll = [
    {
      field: "accepted",
      headerName: "Accept",
      flex: 0.5,
      minWidth: 100,
      maxWidth:100,
      renderHeader: () => (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={selectAcceptAll}
            onChange={() => {
              setSelectAll((prev) => {
                return !prev;
              });
              setRejectAll(false);
              acceptAll();
              
            }}
            color="secondary"
          />
          <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
            Accept
          </Typography>
        </Box>
      ),
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={
                pageData[params?.id].acceptedBy.includes(userProfile?.email)
                  ? true
                  : false
              }
              onClick={() => {
                if (
                  !pageData[params?.id].acceptedBy.includes(userProfile?.email)
                ) {
                  setRejectAll(false);
                  setPageData((prev) => {
                    return prev.map((item) => {
                      if (item?.id === params?.row?.id) {
                        return {
                          ...item,
                          rejectedBy: item?.rejectedBy?.filter((item) => {
                            return item !== userProfile?.email;
                          }),
                          acceptedBy: [...item?.acceptedBy, userProfile?.email],
                        };
                      } else {
                        return item;
                      }
                    });
                  });
                } else {
                  if (selectAcceptAll) {
                    setSelectAll(false);
                  }
                  setPageData((prev) => {
                    return prev.map((item) => {
                      if (item?.id === params?.row?.id) {
                        return {
                          ...item,
                          acceptedBy: item?.acceptedBy?.filter((item) => {
                            return item !== userProfile?.email;
                          }),
                        };
                      } else {
                        return item;
                      }
                    });
                  });
                }
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "rejected",
      headerName: "Rejected",
      flex: 0.5,
      minWidth: 100,
      maxWidth:100,
      renderHeader: () => (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={selectRejectAll}
            onChange={() => {
              setRejectAll((prev) => {
                return !prev;
              });
              rejectAll();
              setSelectAll(false);
              
            }}
            color="secondary"
          />
          <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
            Rejected
          </Typography>
        </Box>
      ),
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={
                pageData[params?.id].rejectedBy.includes(userProfile?.email)
                  ? true
                  : false
              }
              onClick={() => {
                if (
                  !pageData[params?.id].rejectedBy.includes(userProfile?.email)
                ) {
                  setSelectAll(false);
                  setPageData((prev) => {
                    return prev.map((item) => {
                      if (item?.id === params?.row?.id) {
                        return {
                          ...item,
                          rejectedBy: [...item?.rejectedBy, userProfile?.email],
                          acceptedBy: item?.acceptedBy?.filter((item) => {
                            return item !== userProfile?.email;
                          }),
                        };
                      } else {
                        return item;
                      }
                    });
                  });
                } else {
                  if (selectRejectAll) {
                    setRejectAll(false);
                  }
                  setPageData((prev) => {
                    return prev.map((item) => {
                      if (item?.id === params?.row?.id) {
                        return {
                          ...item,
                          accepted: false,
                          rejected: false,
                          rejectedBy: item?.rejectedBy?.filter((item) => {
                            return item !== userProfile?.email;
                          }),
                        };
                      } else {
                        return item;
                      }
                    });
                  });
                }
              }}
            />
          </Box>
        );
      },
    },
    
    {
      field: "useCaseLineage",
      headerName: "Use Case",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params)=>{
        return ShowPath({value:params?.row?.useCaseLineage})
      }
    },
    {
      field: "featureLineage",
      headerName: "Feature",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params)=>{
        return ShowPath({value:params?.row?.featureLineage})
      }
    },
    {
      field: "acceptedBy",
      headerName: "Accepted By",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return pageData[params?.id].acceptedBy?.length === 0
          ? "N/A"
          : pageData[params?.id].acceptedBy.join(", ");
      },
    },
    {
      field: "rejectedBy",
      headerName: "Rejected By",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return pageData[params?.id].rejectedBy?.length === 0
          ? "N/A"
          : pageData[params?.id].rejectedBy.join(", ");
      },
    },
  ];
  const columnDataUseCase = [
    {
      field: "accepted",
      headerName: "Accept",
      flex: 0.5,
      minWidth: 100,
      maxWidth:100,
      renderHeader: () => (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={selectAcceptAll}
            onChange={() => {
              setSelectAll((prev) => {
                return !prev;
              });
              setRejectAll(false);
              acceptAll();
              
            }}
            color="secondary"
          />
          <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
            Accept
          </Typography>
        </Box>
      ),
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={
                pageData[params?.id].acceptedBy.includes(userProfile?.email)
                  ? true
                  : false
              }
              onClick={() => {
                if (
                  !pageData[params?.id].acceptedBy.includes(userProfile?.email)
                ) {
                  setRejectAll(false);
                  setPageData((prev) => {
                    return prev.map((item) => {
                      if (item?.id === params?.row?.id) {
                        return {
                          ...item,
                          rejectedBy: item?.rejectedBy?.filter((item) => {
                            return item !== userProfile?.email;
                          }),
                          acceptedBy: [...item?.acceptedBy, userProfile?.email],
                        };
                      } else {
                        return item;
                      }
                    });
                  });
                } else {
                  if (selectAcceptAll) {
                    setSelectAll(false);
                  }
                  setPageData((prev) => {
                    return prev.map((item) => {
                      if (item?.id === params?.row?.id) {
                        return {
                          ...item,
                          acceptedBy: item?.acceptedBy?.filter((item) => {
                            return item !== userProfile?.email;
                          }),
                        };
                      } else {
                        return item;
                      }
                    });
                  });
                }
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "rejected",
      headerName: "Rejected",
      flex: 0.5,
      minWidth: 100,
      maxWidth:100,
      renderHeader: () => (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={selectRejectAll}
            onChange={() => {
              setRejectAll((prev) => {
                return !prev;
              });
              rejectAll();
              setSelectAll(false);
              
            }}
            color="secondary"
          />
          <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
            Rejected
          </Typography>
        </Box>
      ),
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={
                pageData[params?.id].rejectedBy.includes(userProfile?.email)
                  ? true
                  : false
              }
              onClick={() => {
                if (
                  !pageData[params?.id].rejectedBy.includes(userProfile?.email)
                ) {
                  setSelectAll(false);
                  setPageData((prev) => {
                    return prev.map((item) => {
                      if (item?.id === params?.row?.id) {
                        return {
                          ...item,
                          rejectedBy: [...item?.rejectedBy, userProfile?.email],
                          acceptedBy: item?.acceptedBy?.filter((item) => {
                            return item !== userProfile?.email;
                          }),
                        };
                      } else {
                        return item;
                      }
                    });
                  });
                } else {
                  if (selectRejectAll) {
                    setRejectAll(false);
                  }
                  setPageData((prev) => {
                    return prev.map((item) => {
                      if (item?.id === params?.row?.id) {
                        return {
                          ...item,
                          accepted: false,
                          rejected: false,
                          rejectedBy: item?.rejectedBy?.filter((item) => {
                            return item !== userProfile?.email;
                          }),
                        };
                      } else {
                        return item;
                      }
                    });
                  });
                }
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "useCaseLineage",
      headerName: "Use Case",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params)=>{
        return ShowPath({value:params?.row?.useCaseLineage})
      }
    },
    {
      field: "featureLineage",
      headerName: "Feature",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params)=>{
        return ShowPath({value:params?.row?.featureLineage})
      }
    },
    {
      field: "acceptedBy",
      headerName: "Accepted By",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return pageData[params?.id].acceptedBy?.length === 0
          ? "N/A"
          : pageData[params?.id].acceptedBy.join(", ");
      },
    },
    {
      field: "rejectedBy",
      headerName: "Rejected By",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return pageData[params?.id].rejectedBy?.length === 0
          ? "N/A"
          : pageData[params?.id].rejectedBy.join(", ");
      },
    },
  ];
  const columnDataFeature = [
    {
      field: "accepted",
      headerName: "Accept",
      flex: 0.5,
      minWidth: 100,
      maxWidth:100,
      renderHeader: () => (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={selectAcceptAll}
            onChange={() => {
              setSelectAll((prev) => {
                return !prev;
              });
              setRejectAll(false);
              acceptAll();
              
            }}
            color="secondary"
          />
          <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
            Accept
          </Typography>
        </Box>
      ),
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={
                pageData[params?.id].acceptedBy.includes(userProfile?.email)
                  ? true
                  : false
              }
              onClick={() => {
                if (
                  !pageData[params?.id].acceptedBy.includes(userProfile?.email)
                ) {
                  setRejectAll(false);
                  setPageData((prev) => {
                    return prev.map((item) => {
                      if (item?.id === params?.row?.id) {
                        return {
                          ...item,
                          rejectedBy: item?.rejectedBy?.filter((item) => {
                            return item !== userProfile?.email;
                          }),
                          acceptedBy: [...item?.acceptedBy, userProfile?.email],
                        };
                      } else {
                        return item;
                      }
                    });
                  });
                } else {
                  if (selectAcceptAll) {
                    setSelectAll(false);
                  }
                  setPageData((prev) => {
                    return prev.map((item) => {
                      if (item?.id === params?.row?.id) {
                        return {
                          ...item,
                          acceptedBy: item?.acceptedBy?.filter((item) => {
                            return item !== userProfile?.email;
                          }),
                        };
                      } else {
                        return item;
                      }
                    });
                  });
                }
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "rejected",
      headerName: "Rejected",
      flex: 0.5,
      minWidth: 100,
      maxWidth:100,
      renderHeader: () => (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={selectRejectAll}
            onChange={() => {
              setRejectAll((prev) => {
                return !prev;
              });
              rejectAll();
              setSelectAll(false);
              
            }}
            color="secondary"
          />
          <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
            Rejected
          </Typography>
        </Box>
      ),
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={
                pageData[params?.id].rejectedBy.includes(userProfile?.email)
                  ? true
                  : false
              }
              onClick={() => {
                if (
                  !pageData[params?.id].rejectedBy.includes(userProfile?.email)
                ) {
                  setSelectAll(false);
                  setPageData((prev) => {
                    return prev.map((item) => {
                      if (item?.id === params?.row?.id) {
                        return {
                          ...item,
                          rejectedBy: [...item?.rejectedBy, userProfile?.email],
                          acceptedBy: item?.acceptedBy?.filter((item) => {
                            return item !== userProfile?.email;
                          }),
                        };
                      } else {
                        return item;
                      }
                    });
                  });
                } else {
                  if (selectRejectAll) {
                    setRejectAll(false);
                  }
                  setPageData((prev) => {
                    return prev.map((item) => {
                      if (item?.id === params?.row?.id) {
                        return {
                          ...item,
                          accepted: false,
                          rejected: false,
                          rejectedBy: item?.rejectedBy?.filter((item) => {
                            return item !== userProfile?.email;
                          }),
                        };
                      } else {
                        return item;
                      }
                    });
                  });
                }
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "featureLineage",
      headerName: "Feature",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params)=>{
        return ShowPath({value:params?.row?.featureLineage})
      }
    },
    {
      field: "useCaseLineage",
      headerName: "Use Case",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params)=>{
        return ShowPath({value:params?.row?.useCaseLineage})
      }
    },

    {
      field: "acceptedBy",
      headerName: "Accepted By",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return pageData[params?.id].acceptedBy?.length === 0
          ? "N/A"
          : pageData[params?.id].acceptedBy.join(", ");
      },
    },
    {
      field: "rejectedBy",
      headerName: "Rejected By",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return pageData[params?.id].rejectedBy?.length === 0
          ? "N/A"
          : pageData[params?.id].rejectedBy.join(", ");
      },
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <HeaderNaming data={HeaderData} />
        {isloading ? (
          <CircularProgress />
        ) : (
          <>
            <DataGrid
              columns={
                pageType === "overAll"
                  ? columnDataOverAll
                  : pageType === "specificUseCase"
                  ? columnDataUseCase
                  : pageType === "specificFeature"
                  ? columnDataFeature
                  : []
              }
              rows={pageData}
              hideFooter
              sx={{
                "& .super-app-theme--header": {
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  fontSize: "14px",
                  ".MuiSvgIcon-root": {
                    color: "white",
                  },
                },
                "& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaders": {
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "normal",
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
              }}
            />
            <Pagination
              count={totalPages?.current}
              page={currentPageNumber}
              color="primary"
              onChange={async (event, pageNumber) => {
                await handlePageChange(event, pageNumber);
              }}
              sx={{ mt: 2, display: "flex", justifyContent: "center" }}
            />
            <Box sx={{ marginTop: "20px" }}>
              <Button
                sx={{ width: "300px" }}
                color="primary"
                variant="contained"
                disableRipple
                onClick={async () => {
                  const reponse = await saveData();
                  if (reponse) {
                    navigate("/usecase-to-feature");
                  }
                }}
              >
                Save & Exit to Main Mapping Page
              </Button>
            </Box>
          </>
        )}
      </Box>
    </ThemeProvider>
  );
}

export default MappingRecommendation;

const ShowPath = (params) => {
  const [showCell, setShowCell] = useState(false);
  const { value } = params;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        width: "100%",
        overflowY: "hidden",
        gap: "0px 10px",
      }}
    >
      <Box>
        <IconButton
          onClick={() => {
            setShowCell(!showCell);
          }}
        >
          {showCell ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
        </IconButton>
      </Box>
      <Box>{showCell ? value.join(" > ") : value[value.length - 1]}</Box>
    </Box>
  );
};