import React, { useState, useEffect, useContext } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Box,
  Divider,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  Snackbar,
  Alert,
  CircularProgress,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { UserProfileContext } from "../../context/UserContext";
import { apiService } from "../../utils/utils.js";
import HeaderNaming from "../../components/HeaderNaming/HeaderNaming.jsx";
import theme from "../../theme/theme.js";

// const data = {
//   'End-user Organisation Name': 'dummyEndOrg1',
//   'Buyer Guide Name': 'DummyBuyerGuide',
//   'Scenario Name': 'dummyScenarioName'
// };

const CustomRadio = styled(Radio)({
  "&.Mui-checked": {
    color: "#4f40ba",
  },
});

const CustomCheckbox = styled(Checkbox)({
  "&.Mui-checked": {
    color: "#4f40ba",
  },
});

const ReportOption = () => {
  const [thresholdType, setThresholdType] = useState("maximumNumberOfVendors");
  const [isLoading, setIsLoading] = useState(false);
  const [numVendors, setNumVendors] = useState(1);
  const [numOffersPerVendor, setNumOffersPerVendor] = useState(1);
  const [numOffers, setNumOffers] = useState(1);
  const [primaryMetric, setPrimaryMetric] = useState("offerScore");
  const [bias, setBias] = useState({
    vendorBias: true,
    offerBias: true,
  });

  const [biasDropDown, setBiasDropDown] = useState({
    vendorBias: "",
    offerBias: "",
  });

  const isMobile = useMediaQuery("(max-width:600px)");
  const {
    currentOrg,
    currentOrganization,
    buyerGuideName,
    scenarioName,
    userProfile,
    notification,
    currentBuyerGuideId,
    currentScenarioId,
    currentPackage,
    handleCloseNotification,
  } = useContext(UserProfileContext);

  const data = {
    "End-user Organisation Name":
      userProfile?.profileType === "endUser" ? userProfile?.companyName : currentOrganization,
    "Buyer Guide Name": buyerGuideName,
    "Scenario Name": scenarioName,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/getScenarioProperties?",
          {
            method: "POST",
            data: {
              email: userProfile?.email,
              currentOrganisation: currentOrg,
              currentBuyerGuide: currentBuyerGuideId,
              currentScenario: currentScenarioId,
              currentPackage: currentPackage?.id,
              selectedOption: "Maximum Number of Vendors", // This can be dynamic if needed
            },
          }
        );
        if (response) {
          setThresholdType(
            response.selectedOption === "Maximum Number of Vendors"
              ? "maximumNumberOfVendors"
              : "maximumNumberOfOffers"
          );
          setNumVendors(response.maxNoOfVendors || 1);
          setNumOffers(response.maxNoOfOffers || 1);
          setNumOffersPerVendor(response.maxOffersPerVendor || 1);
          setPrimaryMetric(
            response.primaryMetric === "Offer Score" ? "offerScore" : "offerRelevance"
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentOrg, currentPackage?.id, currentBuyerGuideId, userProfile,currentScenarioId]);

  const handleThresholdTypeChange = (e) => {
    setThresholdType(e.target.value);
  };

  const handlePrimaryMetricChange = (e) => {
    setPrimaryMetric(e.target.value);
  };

  const handleBiasChange = (e) => {
    const { value, checked } = e.target;
    setBias((prevBias) => ({
      ...prevBias,
      [value]: checked, // Update the correct bias value based on checkbox
    }));
  };

  const handleBiasDrownDownChange = (e) => {
    const { name, value } = e.target; // Extract name and value from event
    setBiasDropDown((prevBiasDropDown) => ({
      ...prevBiasDropDown,
      [name]: value, // Update the correct dropdown state based on the name
    }));
  };

  const navigate = useNavigate();

  const handleSaveAndExit = async () => {
    const formData = {
      thresholdType,
      numVendors,
      numOffersPerVendor,
      numOffers,
      primaryMetric,
    };

    // try {
    //   await apiService(
    //     "https://newtestfuncpython.azurewebsites.net/api/updateScenarioProperties?",
    //     {
    //       method: "POST",
    //       data: {
    //         email: userProfile?.email,
    //         currentOrganisation: currentOrganization,
    //         currentBuyerGuide: buyerGuideName,
    //         currentScenario: scenarioName,
    //         primaryMetric: formData.primaryMetric === "offerScore" ? "Offer Score" : "Offer Relevance",
    //         selectedOption: formData.thresholdType === "maximumNumberOfVendors" ? "Maximum Number of Vendors" : "Maximum Number of Offers",
    //         maxNoOfVendors: formData.numVendors,
    //         maxNoOfOffers: formData.numOffers,
    //         maxOffersPerVendor: formData.numOffersPerVendor,
    //       },
    //     }
    //   );
    // } catch (error) {
    //   console.error("Error updating data:", error);
    // }

    navigate("/results/comparative-analysis", {
      state: {
        formData,
      },
    });
  };

  return isLoading ? (
    <Box height="80vh" width="100%" justifyContent="center" alignItems="center" display="flex">
      <CircularProgress />
    </Box>
  ) : (
    <Box sx={{ height: "100%" }}>
      <HeaderNaming data={data} />
      <Box p={0} sx={{ height: "70%" }}>
        <Grid container spacing={3} direction={isMobile ? "column" : "row"}>
          <Grid item xs={12} sm={5}>
            <FormControl component="fieldset">
              <Typography data-test-id="name-Shortlist Threshold Type" variant="h3">
                Shortlist Threshold Type
              </Typography>
              <RadioGroup value={thresholdType} onChange={handleThresholdTypeChange}>
                <FormControlLabel
                  data-test-id="name-Maximum Number of Vendors"
                  value="maximumNumberOfVendors"
                  control={<CustomRadio data-test-id="radio-Maximum Number of Vendors" />}
                  label="Maximum Number of Vendors"
                />
                {thresholdType === "maximumNumberOfVendors" && (
                  <Box mt={2} ml={3}>
                    <TextField
                      data-test-id="text-Number of vendors to be shortlisted"
                      label="Number of vendors to be shortlisted"
                      type="number"
                      value={numVendors}
                      onChange={(e) => setNumVendors(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      data-test-id="text-Maximum Number of offers per vendor to be shortlisted"
                      label="Maximum Number of offers per vendor to be shortlisted"
                      type="number"
                      value={numOffersPerVendor}
                      onChange={(e) => setNumOffersPerVendor(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                  </Box>
                )}
                <FormControlLabel
                  data-test-id="name-Maximum Number of Offers"
                  value="maximumNumberOfOffers"
                  control={<CustomRadio data-test-id="radio-Maximum Number of Offers" />}
                  label="Maximum Number of Offers"
                />
                {thresholdType === "maximumNumberOfOffers" && (
                  <Box mt={2} ml={3}>
                    <TextField
                      data-test-id="text-Number of offers to be shortlisted"
                      label="Number of offers to be shortlisted"
                      type="number"
                      value={numOffers}
                      onChange={(e) => setNumOffers(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                  </Box>
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Divider
            orientation={isMobile ? "horizontal" : "vertical"}
            flexItem
            variant="middle"
            sx={{ my: 2, mx: isMobile ? 0 : 2 }}
          />
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", flexDirection: "column", gap: "50px 0px" }}
          >
            <FormControl component="fieldset">
              <Typography data-test-id="name-Primary Metric for Shortlisting" variant="h3">
                Primary Metric for Shortlisting
              </Typography>
              <RadioGroup value={primaryMetric} onChange={handlePrimaryMetricChange}>
                <FormControlLabel
                  data-test-id="name-Offer Score"
                  value="offerScore"
                  sx={{ display: "flex", width: "fit-content" }}
                  control={<CustomRadio data-test-id="radio-Offer Score" />}
                  label="Offer Score"
                />
                <FormControlLabel
                  data-test-id="name-Offer Relevance"
                  value="offerRelevance"
                  sx={{ display: "flex", width: "fit-content" }}
                  control={<CustomRadio data-test-id="radio-Offer Relevance" />}
                  label="Offer Relevance"
                />
              </RadioGroup>
            </FormControl>

            {/* --------------------------------------------- */}

            <FormControl component="fieldset">
              <Typography data-test-id="name-Bias Settings" variant="h3">
                Bias Settings
              </Typography>

              <FormControlLabel
                data-test-id="name-Vendor Bias"
                value="vendorBias"
                sx={{ display: "flex", width: "fit-content" }}
                control={
                  <CustomCheckbox onChange={handleBiasChange} data-test-id="checkbox-Vendor Bias" />
                }
                label="Vendor Bias"
                checked={bias.vendorBias}
              />
              {bias.vendorBias && (
                <FormControl sx={{ maxWidth: "350px", width: "100%" }}>
                  <Select
                    labelId="vendorBias"
                    id="bias-vendorBias-dropDown"
                    value={biasDropDown.offerBias}
                    name="vendorBias"
                    sx={{ fontFamily: theme.typography.h1.fontFamily }}
                    onChange={handleBiasDrownDownChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    // input={<BootstrapInput />}
                  >
                    <MenuItem value="" disabled={true}>
                      Select Vendor
                    </MenuItem>
                    <MenuItem value={"Vendor 1 "}>Vendor 1</MenuItem>
                    <MenuItem value={"Vendor 2"}>Vendor 2</MenuItem>
                    <MenuItem value={"Vendor 3"}>Vendor 3</MenuItem>
                  </Select>
                </FormControl>
              )}

              <FormControlLabel
                data-test-id="name-Offer Bias"
                value="offerBias"
                sx={{ display: "flex", width: "fit-content" }}
                control={
                  <CustomCheckbox onChange={handleBiasChange} data-test-id="radio-Offer Bias" />
                }
                label="Offer Bias"
                checked={bias.offerBias}
              />
              {bias.offerBias && (
                <FormControl sx={{ maxWidth: "350px", width: "100%" }}>
                  <Select
                    labelId="offerBias"
                    id="bias-offerBias-dropDown"
                    value={biasDropDown.offerBias}
                    sx={{ fontFamily: theme.typography.h1.fontFamily }}
                    name="offerBias"
                    onChange={handleBiasDrownDownChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="" disabled={true}>
                      Select Offer
                    </MenuItem>
                    <MenuItem value={"Offer 1 "}>Offer 1</MenuItem>
                    <MenuItem value={"Offer 2"}>Offer 2</MenuItem>
                    <MenuItem value={"Offer 3"}>Offer 3</MenuItem>
                  </Select>
                </FormControl>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 4,
          ml: 2,
        }}
      >
        <Button
          style={{ width: "250px" }}
          color="secondary"
          variant="contained"
          disableRipple
          onClick={handleSaveAndExit}
        >
          <Typography data-test-id="dashboard" color="white">
            Save & Exit to Dashboard
          </Typography>
        </Button>
      </Box>
      <Snackbar open={notification.open} autoHideDuration={6000} onClose={handleCloseNotification}>
        <Alert onClose={handleCloseNotification} severity={notification.severity}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ReportOption;
