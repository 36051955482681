import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { Box, Button, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material";
import { Snackbar, Alert } from "@mui/material";
import { Delete, Edit, FileCopy, Visibility, Add } from "@mui/icons-material";
import { apiService } from "../utils/utils";
import HeaderNaming from "../components/HeaderNaming/HeaderNaming.jsx";
import { UserProfileContext } from "../context/UserContext.jsx";
import NewScenarioModal from "../components/ScenarioModal.jsx";
import "./GridLayout/GridComponent.css";
import DataGridTable from "../components/DataGridTable.jsx";
import theme from "../theme/theme.js";
import { useNavigate } from "react-router-dom";
import NoDataAvilable from "../components/NoDataAvilable.jsx";

const SelectScenario2 = () => {
  const {
    currentPackage,
    currentOrganization,
    buyerGuideName,
    userProfile,
    // currentOrganizationId,
    currentBuyerGuideId,
    currentOrg,
    setScenarioName,
    setCurrentScenarioId,
    purchasePackage,
  } = useContext(UserProfileContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  //   rows, columns, scenarios, toast, isScenariosLoading
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [scenarios, setScenarios] = useState([]);
  const errorMessage = useRef("");
  const [isScenarioLoading, setIsScenarioLoading] = useState(false);
  const [selectedScenarioId, setSelectedScenarioId] = useState("");
  const [newScenarioName, setNewScenarioName] = useState("");
  const [openModal, setOpenModal] = useState({
    state: false,
    title: "",
    label: "",
    submit: () => {},
  });
  const [isAdding, setIsAdding] = useState(false);
  const [, setMessage] = useState("");
  const navigate = useNavigate();
  const data = {
    "End-user Organisation Name":
      userProfile?.profileType === "endUser" ? userProfile?.companyName : currentOrganization,
    "Buyer Guide Name": buyerGuideName,
    "Purchased Package": currentPackage?.package_type,
  };

  // SCENARIO LIST
  const getScenariosList = () => {
    apiService(
      `https://newtestfuncpython.azurewebsites.net/api/getAllScenarios?`,
      {
        method: "POST",
        data: {
          email: userProfile?.email,
          currentBuyerGuide: currentBuyerGuideId,
          currentOrganisation: currentOrg,
          currentPackage: currentPackage?.id,
        },
      }, // Options, if needed
      // 'email', 'currentOrganisation', 'currentBuyerGuide', and 'currentPackage'.
      setIsScenarioLoading,
      setScenarios,
      (error) => {
        // Handle error if needed
        console.error("Failed to load base use cases:", error);
      }
    );
  };
  //   [userProfile?.email, currentOrg, currentBuyerGuideId, currentPackage?.id]);

  useEffect(() => {
    getScenariosList();
    // eslint-disable-next-line
  }, [userProfile, currentBuyerGuideId, currentOrg, currentPackage]);

  //DELETE SCENARIO
  const deleteScenario = async (id) => {
    const response = await apiService(
      "https://newtestfuncpython.azurewebsites.net/api/deleteScenario?",
      {
        method: "POST",
        data: {
          email: userProfile?.email,
          currentOrganisation: currentOrg,
          currentBuyerGuide: currentBuyerGuideId,
          removeScenario: id,
          currentPackage: currentPackage?.id,
        },
      },
      null,
      null,
      (error) => {
        // Handle error if needed
        console.error("Failed to create/update users:", error);
      }
    );
    if (response) {
      getScenariosList();
    }
  };

  //EDIT SCENARIO
  const editScenario = (id) => {
    apiService(
      "https://newtestfuncpython.azurewebsites.net/api/editScenario?",
      {
        method: "POST",
        data: {
          currentScenario: id,
        },
      },
      null,
      null,
      (error) => {
        // Handle error if needed
        console.error("Failed to create/update users:", error);
      }
    ); // Trigger refresh
  };

  // DUPLICATE SCENARIO
  const duplicateScenario = async () => {
    try {
      const response = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/duplicateScenario?",
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            currentOrganisation: currentOrg,
            currentBuyerGuide: currentBuyerGuideId,
            copyScenario: selectedScenarioId,
            newScenarioName: newScenarioName,
            currentPackage: currentPackage?.id,
          },
        },
        setIsAdding,
        null,
        (error) => {
          // Handle error if needed
          console.error("Failed to create/update users:", error);
        }
      );
      if (response) {
        getScenariosList();
      }
      setOpenModal((prevstate) => {
        return {
          ...prevstate,
          state: false,
        };
      });
      // Trigger refresh
    } catch (err) {
      console.log(err);
      errorMessage.current = err.response.data.message;
      setSnackbarOpen(true);
    }
  };

  const handleAction = (action, id) => {
    if (action === "Edit") {
      if (purchasePackage !== "Solo Starter" || purchasePackage) {
        navigate("/edit-scenario/update-use-cases");
        editScenario(id);
      }
    }
    if (action === "Delete") {
      deleteScenario(id);
    }
    if (action === "View") {
      navigate("/results/comparative-analysis");
    }
    if (action === "Duplicate") {
      setOpenModal((prevstate) => {
        return {
          ...prevstate,
          state: true,
          submit: duplicateScenario,
          title: "Duplicate scenario",
          label: "New Scenario Name",
        };
      });
    }
    if (action === "Preview Report") {
      navigate("/view-reports");
    }
  };

  const getActionIcon = (action) => {
    switch (action) {
      case "View":
        return <Visibility color="secondary" />;
      case "Duplicate":
        return <FileCopy color="secondary" />;
      case "Delete":
        return <Delete color="secondary" />;
      case "Edit":
        return <Edit color="secondary" />;
      default:
        return null;
    }
  };

  //table data
  useMemo(() => {
    if (scenarios?.[0]) {
      const columnsNames = Object.keys(scenarios[0])
        .filter((key) => key !== "urlPreview" && key !== "id")
        .map((header, index) => ({
          field: header, // Use actual keys from scenarios (like 'status', 'Scenario Name', etc.)
          headerName:
            header === "Scenario Name"
              ? header
              : header === "status"
              ? "Status"
              : header === "action"
              ? "Action"
              : header,
          flex: 1,
          minWidth: 220,
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            return (
              <Box
                sx={{
                  overflowY: "hidden", // Apply overflowY: hidden to each column
                  display: "flex",
                  justifyContent: index === 2 ? "space-evenly" : "center", // To target each column of the table
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                {Array.isArray(params.value)
                  ? params.value.map((value, index) => (
                      <Tooltip title={value} key={index}>
                        <IconButton
                          data-test-id={`${value}`}
                          sx={{
                            color: theme.palette.secondary.main,
                            padding: 1,
                          }}
                          onClick={() => {
                            console.log(params);
                            setScenarioName(params.row["Scenario Name"]);
                            setSelectedScenarioId(params.row["id"]); // passing scenario id 
                            setCurrentScenarioId(params.row["id"]); // passing scenario id 
                            localStorage.setItem("scenarioName", params.row["Scenario Name"]);
                            localStorage.setItem("currentScenarioId", params.row["id"]); // passing scenario id 
                            localStorage.setItem(
                              "currentScenario",
                              JSON.stringify({
                                id: params.row["id"], // passing scenario id 
                                name: params.row["Scenario Name"],
                                status: params.row["status"],
                              })
                            );
                            handleAction(value, params.row["id"]);
                          }}
                        >
                          {getActionIcon(value)}
                        </IconButton>
                      </Tooltip>
                    ))
                  : params.value}
              </Box>
            );
          },
        }));

      setColumns(columnsNames); // Set the new columns
    }

    const rowData = scenarios?.map((row, index) => {
      const perRow = {};

      // perRow["id"] = index; // Assign unique id for each row
      perRow["id"] = row.id; // store scenarioId for future use

      // Loop through each key in the row, excluding 'urlPreview' and 'id'
      Object.keys(row)
        .filter((key) => key !== "urlPreview" )
        .forEach((key) => {
          perRow[key] = row[key]; // Populate perRow with filtered data
        });
      return perRow;
    });
    setRows(rowData); // Set the new rows

    // dont remove this below comment
    // eslint-disable-next-line
  }, [scenarios]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // ADD SCENARIO
  const addNewScenario = async () => {
    try {
      const response = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/createNewScenario?",
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            currentOrganisation: currentOrg,
            currentBuyerGuide: currentBuyerGuideId,
            newScenarioName: newScenarioName,
            currentPackage: currentPackage?.id,
          },
        },
        setIsAdding,
        setMessage,
        (error) => {
          // Handle error if needed
          console.error("Failed to add new scenario", error);
        }
      );
      if (response) {
        getScenariosList();
      }
      setOpenModal((prevstate) => {
        return {
          ...prevstate,
          state: false,
        };
      });
    } catch (error) {
      console.log(error.response);
      errorMessage.current = error.response.data.message;
      setSnackbarOpen(true);
    }
  };

  return isScenarioLoading ? (
    <Box
      height="250px"
      width="100%"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      display="flex"
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box>
      <HeaderNaming data-cy="header-name" data={data} />

      <Box
        className="thinner-scrollbar"
        sx={{ backgroundColor: "transparent", boxShadow: 3, padding: "10px" }}
      >
        <Button
          data-cy="Create-Button"
          variant="contained"
          color="primary"
          sx={{
            padding: "5px 40px",
            marginBottom: "10px",
          }}
          onClick={() =>
            setOpenModal((prevstate) => {
              return {
                ...prevstate,
                submit: addNewScenario,
                state: true,
                title: "Create New scenario",
                label: "New Scenario Name",
              };
            })
          }
          startIcon={<Add />}
        >
          Create
        </Button>

        {/* table below */}
        <Box
          className="thinner-scrollbar"
          sx={{
            outline: "0.1px solid gray",
            width: "100%",
            borderRadius: "20px",
            overflow: "hidden",
          }}
        >
          {scenarios.length > 0 ? (
            <DataGridTable data-test-id="td" name="selectScenario" rows={rows} columns={columns} />
          ) : (
            <NoDataAvilable
              message={
                <>
                  No Scenario Available. Click on
                  <Typography component="span" marginX={2} color="primary">
                    'Create +'
                  </Typography>
                  Button to create one.
                </>
              }
            />
          )}
        </Box>
      </Box>

      {/* popup and alert section below */}

      {openModal.title === "Create New scenario" && (
        <NewScenarioModal
          data-test-id="create-scenerio-box"
          open={openModal.state}
          handleClose={() => setOpenModal((prevstate) => ({ ...prevstate, state: false }))}
          handleSubmit={addNewScenario}
          value={newScenarioName}
          setValue={setNewScenarioName}
          title={"Create New scenario"}
          label={"New Scenario Name"}
          isAdding={isAdding}
        />
      )}

      {openModal.title === "Duplicate scenario" && (
        <NewScenarioModal
          data-test-id="create-scenerio-box"
          open={openModal.state}
          handleClose={() => setOpenModal((prevstate) => ({ ...prevstate, state: false }))}
          handleSubmit={duplicateScenario}
          value={newScenarioName}
          setValue={setNewScenarioName}
          title={openModal.title}
          label={openModal.label}
          isAdding={isAdding}
        />
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: "100%" }}>
          {errorMessage.current}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SelectScenario2;
