import React, { useState } from "react";
import { Box, useMediaQuery, Typography, Tooltip, IconButton } from "@mui/material";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";

export default function LabelCopyViewClose({
  widgetLabel,
  widgetKey,
  isTableVisible,
  handleCopyWidget,
  toggleTableVisibility,
  handleRemoveWidget,
  shortLabels,
  heightChange,
}) {
  const [hoveredElement, setHoveredElement] = useState(null);
  const issmallscreen = useMediaQuery((theme) => theme.breakpoints.down("md")); // Media query to adjust layout for small screens

  return (
    <Tooltip title={hoveredElement === "box" && "Drag the box"}>
      <Box
        className="widget"
        sx={{
          height: "40px",
          cursor: "grab",
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          userSelect: "none",
          backgroundColor: "#40bab414",
          borderRadius: "10px",
          ":hover": {
            backgroundColor: "#40bab420",
          },
          ":active": {
            cursor: "grabbing",
          },
        }}
        onMouseEnter={() => setHoveredElement("box")} // Set to 'box' on hover
        onMouseLeave={() => setHoveredElement(null)} // Reset on mouse leave
      >
        <Box
          className="widget-header-container"
          sx={{
            // width: "100%",
            display: "flex",
            position: "relative",
            justifyContent: "center",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Tooltip title={hoveredElement === "title" && widgetLabel}>
            <Typography
              variant="h5"
              sx={{
                cursor: "default",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
              className="widget-header no-drag"
              onMouseEnter={() => setHoveredElement("title")} // Set to 'title' on hover
              onMouseLeave={() => setHoveredElement("box")} // Reset on mouse leave
            >
              {issmallscreen ? shortLabels : widgetLabel}
            </Typography>
          </Tooltip>
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: issmallscreen ? 2 : 5,
            display: "flex",
            alignItems: "center",
            gap: "0px 10px",
          }}
        >
          {/* View Icon */}
          {widgetKey === "chart_box" && (
            <Tooltip title={hoveredElement === "view" && "View"}>
              <IconButton
                className="no-drag"
                data-test-id={`eye-${widgetLabel}`}
                onMouseEnter={() => setHoveredElement("view")} // Set to 'close' on hover
                onMouseLeave={() => setHoveredElement("box")} // Reset on mouse leave
                onClick={toggleTableVisibility}
                aria-label="toggle table visibility"
              >
                {isTableVisible ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Tooltip>
          )}

          {/* Copy Icon  is commented now but we'll be using it in the future so do not remove the code*/}

          {/* <IconButton
              data-test-id={`copy-${widgetLabel}`}
              onClick={() => handleCopyWidget(widgetKey)}
              aria-label="copy widget"
            >
              <FileCopyOutlined />
            </IconButton> */}

          {/* Close Icon */}
          <Tooltip title={hoveredElement === "close" && "Close"}>
            <IconButton
              className="no-drag"
              data-test-id={`close-${widgetLabel}`}
              size="small"
              onMouseEnter={() => setHoveredElement("close")} // Set to 'close' on hover
              onMouseLeave={() => setHoveredElement("box")} // Reset on mouse leave
              onClick={() => handleRemoveWidget(widgetKey)}
              aria-label="remove widget"
            >
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Tooltip>
  );
}
