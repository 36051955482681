import React, { forwardRef, useEffect, useState } from "react";

import {
  Box,
  Typography,
  Tooltip,
  Collapse,
  Alert,
  Button,
  Checkbox,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import useMediaQuery from "@mui/material/useMediaQuery";

const LeftPane = forwardRef(
  (
    {
      leftPaneUseCases,
      setLeftPaneUseCases,
      rightPaneUseCases,
      setRightPaneUseCases,
      useCaseHistory,
      setUseCaseHistory,
      setLoading,
      setNoChildren,
      selectAllChecked,
      setSelectAllChecked,
      showWarning,
      setShowWarning,
      isCollapsed,
      setIsCollapsed,
      renderUseCaseList,
      setFocusedElementId,
      setSelectedElementId,
      setmandatoryStatus,
      mandatoryStatus,
    },
    ref
  ) => {
    const [, setSelectedUseCaseIndex] = useState(null);
    const [focusedIndexLeftPane] = useState(null);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const {clickedUseCase,parentOfLeftPane} = ref
    const [showMandatoryWarning,setMandatoryWarning] = useState(false)
    
    useEffect(() => {
      const allChecked =
        leftPaneUseCases &&
        leftPaneUseCases?.length > 0 &&
        leftPaneUseCases.every(
          (useCase) =>
            useCase.checked &&
            (useCase?.children
              ? useCase.children.every((child) => child.checked)
              : true)
        );
      setSelectAllChecked(allChecked);
    }, [leftPaneUseCases, setSelectAllChecked]);

    const handleToggleCollapse = () => {
      setIsCollapsed(!isCollapsed);
    };

    const handleSelectUseCase = (useCase, index) => {
      if (focusedIndexLeftPane !== null) return;
      setLoading(true);
      setSelectedUseCaseIndex(index);
      if (useCase.children && useCase.children.length > 0) {
        setTimeout(() => {
          setRightPaneUseCases(sortUseCases(useCase.children));
          setNoChildren(false);
          setLoading(false);
        }, 500);
      } else {
        setTimeout(() => {
          setRightPaneUseCases([]);
          setNoChildren(true);
          setLoading(false);
        }, 500);
      }
    };

    const handleBack = (e) => {
      if (useCaseHistory.length > 0) {
        e.stopPropagation();
        let newUseCaseHistory = useCaseHistory.slice(0);
        const lastState = newUseCaseHistory.pop();
        console.log("latest State:",lastState);
        if(newUseCaseHistory.length===0)
        {
           parentOfLeftPane.current=null;
        }else{
           parentOfLeftPane.current=lastState?.parentOfLeftPane
        }
        clickedUseCase.current = lastState?.element_id;
        
        setSelectedElementId(lastState.element_id);
        setFocusedElementId(lastState.element_id);
        const index = lastState.left.findIndex((item) => {
          return item.element_id === lastState.element_id;
        });
        lastState.left[index].children = leftPaneUseCases;
        setRightPaneUseCases(sortUseCases(leftPaneUseCases || []));
        setLeftPaneUseCases(sortUseCases(lastState.left || []));
        setUseCaseHistory([...newUseCaseHistory]);
        setNoChildren(false);
        setIsCollapsed(false);
      }
    };
    const toggleCheckedState = (useCase, checked) => {
      useCase.checked = checked;
      if (useCase.children) {
        useCase.children.forEach((child) => toggleCheckedState(child, checked));
      }
    };

    const handleSelectAllCheckbox = () => {
      const newCheckedState = !selectAllChecked;
      const updatedUseCases = leftPaneUseCases.map((useCase) => {
        const newUseCase = { ...useCase };
        toggleCheckedState(newUseCase, newCheckedState);
        return newUseCase;
      });
      setLeftPaneUseCases(sortUseCases(updatedUseCases));
      setSelectAllChecked(newCheckedState);

      if (rightPaneUseCases.length > 0) {
        const updatedRightPaneUseCases = rightPaneUseCases.map((useCase) => {
          const newUseCase = { ...useCase };
          toggleCheckedState(newUseCase, newCheckedState);
          return newUseCase;
        });
        setRightPaneUseCases(sortUseCases(updatedRightPaneUseCases));
      }
    };

    const propagateImportance = (useCases, setUseCases) => {
      const propagate = (parentImportance, children) => {
        return children.map((child) => ({
          ...child,
          importance: parentImportance,
          children: child.children
            ? propagate(parentImportance, child.children)
            : [],
        }));
      };

      const updatedUseCases = useCases.map((item) => {
        if (item.checked) {
          return {
            ...item,
            children: propagate(item.importance, item.children || []),
          };
        }
        return item;
      });

      setUseCases(sortUseCases(updatedUseCases));
    };
    const propagateMandotory = (useCases, setUseCases) => {
      const propagate = (mandatory, children) => {
        return children.map((child) => ({
          ...child,
          mandatory: mandatory,
          children: child.children ? propagate(mandatory, child.children) : [],
        }));
      };

      const updatedUseCases = useCases.map((item) => {
        if (item.mandatory) {
          return {
            ...item,
            children: propagate(item.mandatory, item.children || []),
          };
        }
        return item;
      });

      setUseCases(updatedUseCases);
    };
    const handlePropagateMandotory = () => {
      
      if(parentOfLeftPane.current!==null)
      {
         if(parentOfLeftPane.current?.mandatory){
              
               let check = false
               leftPaneUseCases?.forEach((item)=>{
                  if(item?.checked===false){
                    
                    setMandatoryWarning(true);
                    return
                  }
               })
               if (check === false)
               {
                  return
               }
         }
      }
      let newMandatoryStatus = JSON.parse(JSON.stringify(mandatoryStatus));
      let newLeftPane = JSON.parse(JSON.stringify(leftPaneUseCases));
      newLeftPane = newLeftPane.map((item, index) => {
        if (item?.checked === false && item?.mandatory) {
          item?.children?.forEach((rightPaneItem) => {
            const existingIndex = newMandatoryStatus.findIndex((item) => {
              return item?.useCaseId === rightPaneItem?.element_id;
            });
            if (existingIndex !== -1) {
              newMandatoryStatus[existingIndex] = {
                ...newMandatoryStatus[existingIndex],
                mandatory: true,
              };
            } else {
              newMandatoryStatus = [
                ...newMandatoryStatus,
                { useCaseId: rightPaneItem?.element_id, mandatory: true },
              ];
            }
          });
        }
        if (item?.checked === true && !item?.mandatory) {
          item?.children?.forEach((rightPaneItem) => {
            newMandatoryStatus = newMandatoryStatus?.filter((mandatoryItem) => {
              return mandatoryItem?.useCaseId === rightPaneItem?.element_id
                ? false
                : true;
            });
          });
        }
        return {
          ...item,
          mandatory: item?.checked ? true : false,
          checked: false,
        };
      });
      newLeftPane.forEach((paneItem) => {
        const existingIndex = newMandatoryStatus.findIndex((item) => {
          return item?.useCaseId === paneItem?.element_id;
        });
        if (existingIndex !== -1) {
          newMandatoryStatus[existingIndex] = {
            ...newMandatoryStatus[existingIndex],
            mandatory: paneItem?.mandatory,
          };
        } else {
          newMandatoryStatus = [
            ...newMandatoryStatus,
            { useCaseId: paneItem?.element_id, mandatory: paneItem?.mandatory },
          ];
        }
      });
      setmandatoryStatus(newMandatoryStatus);
      propagateMandotory(newLeftPane, setLeftPaneUseCases);
      setRightPaneUseCases((prevRightPaneUseCases) => {
        const propagate = (newMandatory, children) => {
          return children.map((child) => ({
            ...child,
            mandatory: newMandatory,
            children: child.children
              ? propagate(newMandatory, child.children)
              : [],
          }));
        };

        const updatedRightPaneUseCases = prevRightPaneUseCases.map(
          (useCase) => {
            const parent = newLeftPane.find((baseUseCase) =>
              baseUseCase.children?.some((child) => child.name === useCase.name)
            );
            const newMandatory = parent?.mandatory || useCase?.mandatory;
            return {
              ...useCase,
              mandatory: newMandatory,
              children: useCase.children
                ? propagate(newMandatory, useCase.children)
                : [],
            };
          }
        );

        return sortUseCases(updatedRightPaneUseCases);
      });
    };

    const handlePropagateDetailsLeftPane = () => {
      const anyChecked = leftPaneUseCases.some((useCase) => useCase.checked);

      if (!anyChecked) {
        setShowWarning(true);
        return;
      }

      setShowWarning(false);
      propagateImportance(leftPaneUseCases, setLeftPaneUseCases);

      setRightPaneUseCases((prevRightPaneUseCases) => {
        const propagate = (parentImportance, children) => {
          return children.map((child) => ({
            ...child,
            importance: parentImportance,
            children: child.children
              ? propagate(parentImportance, child.children)
              : [],
          }));
        };

        const updatedRightPaneUseCases = prevRightPaneUseCases.map(
          (useCase) => {
            const parent = leftPaneUseCases.find((baseUseCase) =>
              baseUseCase.children?.some((child) => child.name === useCase.name)
            );
            const newImportance = parent?.importance || useCase.importance;
            return {
              ...useCase,
              importance: newImportance,
              children: useCase.children
                ? propagate(newImportance, useCase.children)
                : [],
            };
          }
        );

        return sortUseCases(updatedRightPaneUseCases);
      });
    };

    const sortUseCases = (useCases) => {
      return useCases.sort((a, b) => b.importance - a.importance);
    };

    return (
      <Box
        width={{ xs: "100%", md: isCollapsed ? "3%" : "50%" }}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        borderRight={{ md: "1px solid #c9c9c9" }}
        transition="width 0.5s ease"
        position="relative"
        padding="10px"
        sx={{ transition: "all 0.5s ease", flex: 1 }}
      >
        {useCaseHistory.length > 0 && isCollapsed && (
          <Tooltip title="Go Back" arrow>
            <IconButton
              onClick={handleBack}
              style={{ margin: "10px", marginLeft: "30px" }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        )}
        {!isMobile && (
          <Tooltip arrow>
            <IconButton
              onClick={handleToggleCollapse}
              style={{
                margin: "10px",
                marginLeft: "90%",
                transition: "transform 0.5s ease",
              }}
            >
              {isCollapsed ? (
                <KeyboardDoubleArrowRightIcon data-test-id="right-icon" />
              ) : (
                <KeyboardDoubleArrowLeftIcon data-test-id="left-icon" />
              )}
            </IconButton>
          </Tooltip>
        )}
        {!isCollapsed && (
          <>
            {useCaseHistory.length > 0 && (
              <Tooltip title="Go Back" arrow>
                <IconButton
                  onClick={handleBack}
                  style={{ alignSelf: "flex-start", margin: "10px" }}
                >
                  <ArrowBackIcon data-test-id="go-back" />
                </IconButton>
              </Tooltip>
            )}
            <Typography
              data-test-id="use-cases"
              variant="h3"
              sx={{
                marginTop: { xs: "20px", md: "20px" },
                marginLeft: { xs: "-75px", md: "20px" },
                textAlign: "center",
                width: "100%",
              }}
            >
              Use Cases
            </Typography>
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              width="100%"
              marginTop="20px"
              marginLeft={"32px"}
            >
              <Checkbox
                data-test-id="select-all-checkbox"
                color="secondary"
                checked={selectAllChecked}
                onChange={handleSelectAllCheckbox}
              />
              <Typography variant="h6" data-test-id="select-all">
                Select All
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              marginTop="20px"
            >
              {renderUseCaseList(
                leftPaneUseCases,
                handleSelectUseCase,
                setLeftPaneUseCases,
                true
              )}
            </Box>
            <Collapse
              in={showWarning}
              style={{ width: "100%", marginTop: "10px" }}
            >
              <Alert severity="warning" onClose={() => setShowWarning(false)}>
                Please select use case to propagate details
              </Alert>
            </Collapse>
             <Collapse
              in={showMandatoryWarning}
              style={{ width: "100%", marginTop: "10px" }}
            >
              <Alert severity="warning" onClose={() => setMandatoryWarning(false)}>
                Can't make child of mandatory to non-mandatory
              </Alert>
            </Collapse>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
              padding="10px"
              sx={{ alignItems: { md: "flex-start" } }}
            >
              <Button
                data-test-id="propagate-button"
                variant="contained"
                color="primary"
                style={{
                  width: "250px",
                  marginTop: "20px",
                  alignSelf: "flex-start",
                }}
                onClick={handlePropagateDetailsLeftPane}
              >
                Propagate Details
              </Button>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
              padding="10px"
              sx={{ alignItems: { md: "flex-start" } }}
            >
              <Button
                data-test-id="propagate-button"
                variant="contained"
                color="primary"
                style={{
                  width: "250px",
                  marginTop: "20px",
                  alignSelf: "flex-start",
                }}
                onClick={handlePropagateMandotory}
              >
                Mark as Mandatory
              </Button>
            </Box>
          </>
        )}
      </Box>
    );
  }
);

export default LeftPane;