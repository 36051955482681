import React, { useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import theme from "../theme/theme";

// Modify columns to set center alignment for cell values

const DataGridTable = ({ name, columns, rows, menu, page, columnGroupingModel }) => {
  // console.log(columns);
  // Modify columns to set center alignment for cell values
  const centeredColumns = useMemo(
    () =>
      columns?.map((column) => ({
        ...column,
        headerAlign: "center", // center header
      })),
    [columns]
  );

  // Function to check and round a number to 2 decimal places if needed
  const formatValue = (value) => {
    if (typeof value === "number") {
      const decimalPlaces = (value.toString().split(".")[1] || "").length;
      if (decimalPlaces === 0 || decimalPlaces === 1) {
        return value;
      } else {
        return value.toFixed(2);
      }
    }
    return value;
  };

  // Safeguard to ensure `rows` is an array and not boolean
  const formattedRows =
    Array.isArray(rows) && rows.length > 0
      ? rows.map((row) => {
          const newRow = { ...row };
          Object.keys(newRow).forEach((key) => {
            newRow[key] = formatValue(newRow[key]);
          });
          return newRow;
        })
      : []; // Fallback to empty array

  return (
    <Box
      data-test-id={`table-${name}`}
      sx={{
        overflow: "auto",
        "& .super-app-theme--header": {
          backgroundColor: theme.palette.primary.main,
          border: "0.5px solid grey",
          color: "white",
        },
      }}
      style={{
        height: formattedRows.length >= 6 ? 480 : "auto",
        width:
          page === "allocation"
            ? centeredColumns?.length === 2
              ? "40%"
              : centeredColumns?.length === 3
              ? "60%"
              : centeredColumns?.length === 4
              ? "80%"
              : "100%"
            : "100%",
      }}
    >
      <DataGrid
        rows={formattedRows}
        columns={centeredColumns}
        columnGroupingModel={columnGroupingModel}
        disableColumnSorting
        disableRowSelectionOnClick
        hideFooter
        getRowHeight={() => 70}
        scrollbarSize={0}
        rowBufferPx={20}
        sx={{
          minWidth: "100%",
          height: "100%",
          border: "none",

          // borderBottom:"0.5px solid gray ",
          "& .MuiDataGrid-cell:hover": {
            cursor: "default",
          },

          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },

          " .MuiDataGrid-withBorderColor ": {
            borderBottom: "none !important",
            borderTop: "none !important",
            // border: "none !important",
          },

          "& .MuiDataGrid-columnSeparator:not(.MuiDataGrid-columnSeparator--resizable)": {
            display: "none !important",
          },

          ".MuiDataGrid-iconButtonContainer": {
            // marginLeft: "10px !important",
            right: 15,
            position: "absolute",
          },
          "& .MuiDataGrid-sortIcon": {
            color: "white",
          },
          " .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root": {
            color: "white ",
          },
        }}
      />
    </Box>
  );
};

export default DataGridTable;
