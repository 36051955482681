import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Box,
  Grid,
  CircularProgress,
  Typography,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert as MuiAlert,
} from "@mui/material";
import PricingCard from "../components/PricingCard";
import { useNavigate } from "react-router-dom";
import CustomButton from "../components/CustomButton";
import { UserProfileContext } from "../context/UserContext";
import { handleCheckout } from "../utils/utils";

const EndUserPackages = () => {
  const navigate = useNavigate();
  const {
    userProfile,
    setPackageCount,
    notification,
    handleCloseNotification,
  } = useContext(UserProfileContext);
  const [loading, setLoading] = useState(false);
  const [pricingPlans, setPricingPlans] = useState([]);
  // console.log("pricingPlans", pricingPlans);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [selectedPackages, setSelectedPackages] = useState({ 0: [], 1: [] }); // Track selected packages for each tab
  const [openDialog, setOpenDialog] = useState(false);
  const [count, setCount] = useState(0);
  const [priceIds, setPriceIds] = useState({ 0: {}, 1: {} });
  const [priceIdsForSUb, setPriceIdsForSUb] = useState({ 0: {}, 1: {} });
  // console.log("priceIds", priceIds);
  // console.log("priceIdsForSUb", priceIdsForSUb);

  const fetchPlans = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        "https://newtestfuncpython.azurewebsites.net/api/getPrices?",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setPricingPlans(data);
    } catch (error) {
      setError("There was a problem with your fetch operation.");
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPriceIds({ 0: {}, 1: {} });
    setPriceIdsForSUb({ 0: {}, 1: {} });
    setSelectedPackages({ 0: [], 1: [] });
  };

  const handleCardClick = (index, plan) => {
    const selected = selectedPackages[tabValue];
    const isSelected = selected.includes(index);
    const newSelected = isSelected
      ? selected.filter((i) => i !== index)
      : [...selected, index];

    setSelectedPackages({ ...selectedPackages, [tabValue]: newSelected });

    if (newSelected.length > 1 && count === 0) {
      setOpenDialog(true);
      setCount(1);
    }
    if (newSelected.length <= 1) {
      setCount(0);
    }
    if (
      priceIds[tabValue][index] !== plan?.prices?.[+tabValue === 0 ? 1 : 0]?.id
    ) {
      setPriceIds((prev) => {
        return {
          ...prev,
          [tabValue]: {
            ...prev[tabValue],
            [index]: plan?.prices?.[+tabValue === 0 ? 1 : 0]?.id,
          },
        };
      });
    } else {
      delete priceIds[tabValue][index];
    }

    if (
      priceIdsForSUb[tabValue][index] !==
      plan?.prices?.[+tabValue === 0 ? 3 : 2]?.id
    ) {
      setPriceIdsForSUb((prev) => {
        return {
          ...prev,
          [tabValue]: {
            ...prev[tabValue],
            [index]: plan?.prices?.[+tabValue === 0 ? 3 : 2]?.id,
          },
        };
      });
    } else {
      delete priceIdsForSUb[tabValue][index];
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const selectedCount = selectedPackages[tabValue].length; // Calculate the count of selected packages
  useEffect(() => {
    // console.log("settingggggggggggggg", selectedCount);
    setPackageCount(selectedCount);
  }, [selectedCount, setPackageCount]);

  const getProducts = () => {
    let products = [];
    for (let key in priceIds?.[tabValue]) {
      products.push({
        price: priceIds?.[tabValue]?.[key],
        quantity: 1,
      });
    }
    return products;
  };
  const getPackageDetails = () => {
    const packages = {
      duration: selectedPackages,
      volumeUnitsByPackage: selectedPackages?.[tabValue]?.reduce(
        (acc, curr) => {
          acc[curr] = 1;
          return acc;
        },
        {}
      ),
    };
    //  monthlyPackages?.length > 0 &&
    //    monthlyPackages?.forEach((val) => {
    //      if (val === 1) {
    //        packages.tab1State = tab1State;
    //      }
    //      if (val === 2) {
    //        packages.tab2State = tab2State;
    //      }
    //      if (val === 3) {
    //        packages.tab3State = tab3State;
    //      }
    //    });
    return packages;
  };

  const getProductsForSub = () => {
    let products = [];
    for (let key in priceIdsForSUb?.[tabValue]) {
      products.push({
        price: priceIdsForSUb?.[tabValue]?.[key],
        quantity: 1,
        // discounts: [
        //   {
        //     coupon: getDiscountCoupon(volumeUnitsByPackage[key]),
        //   },
        // ],
      });
    }
    return products;
  };

  return (
    <>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          flexDirection: "column",
        }}
        maxWidth="xl"
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <Box
            className="wrapper"
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            color="rgba(0,0,0,0.7)"
            marginBottom="32px"
          >
            <Tabs value={tabValue} onChange={handleTabChange} centered>
              <Tab label="Monthly" />
              <Tab label="Yearly" />
            </Tabs>
            {error ? (
              <Typography color="error">{error}</Typography>
            ) : (
              <Grid container spacing={2} marginTop={2}>
                {pricingPlans?.length > 0 &&
                  pricingPlans.map((plan, index) => (
                    <Grid item xs={12} sm={6} lg={4} xl={3} key={index}>
                      <PricingCard
                        tabValue={tabValue}
                        title={plan.title}
                        price={plan?.prices?.[1]?.unit_amount}
                        description={plan.description}
                        features={plan.features.map((feature) => feature.name)}
                        isActive={selectedPackages[tabValue].includes(index)}
                        onClick={() => handleCardClick(index, plan)}
                        isCurrentPlan={plan.title === "Solo Starter"} // Indicate current plan
                      />
                    </Grid>
                  ))}
              </Grid>
            )}

            <Box
              Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              marginTop={3}
              alignSelf={"flex-start"}
            >
              {selectedCount === 1 && (
                <CustomButton
                  style={{ width: "250px", marginBottom: "7px" }}
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    handleCheckout(
                      userProfile?.email,
                      getProducts,
                      selectedPackages?.[tabValue]?.length,
                      getPackageDetails, // Pass the package count
                      getProductsForSub
                    )
                  }
                  text="Purchase"
                />
              )}
              {selectedPackages[tabValue].length >= 1 && (
                <CustomButton
                  style={{ width: "250px", marginBottom: "7px" }}
                  color="secondary"
                  variant="contained"
                  onClick={() =>
                    navigate("/purchase-section", {
                      state: {
                        tabValue,
                        packages: selectedPackages,
                        priceIds: priceIds?.[tabValue],
                        priceIdsForSUb: priceIdsForSUb?.[tabValue],
                      },
                    })
                  }
                  text="Customise/Bulk Purchase"
                />
              )}
              <CustomButton
                style={{ width: "250px", marginBottom: "7px" }}
                color="secondary"
                variant="contained"
                onClick={() => navigate(-1)}
                text="Back"
              />
            </Box>
          </Box>
        )}
        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleCloseNotification}
        >
          <MuiAlert
            onClose={handleCloseNotification}
            severity={notification.severity}
          >
            {notification.message}
          </MuiAlert>
        </Snackbar>
      </Container>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: { backgroundColor: "white" } }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Customize/Bulk Purchase"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have selected more than one package. This will proceed as a
            Customize/Bulk Purchase.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EndUserPackages;
